import { GeocodeFeature } from './Geocode';

export class GeoLocation {
  name: string;
  county: string;
  longitude: number;
  latitude: number;
  category: string[];

  constructor(geocodeFeature: GeocodeFeature) {
    this.name = geocodeFeature.properties.label;
    this.county = geocodeFeature.properties.county;
    this.longitude = geocodeFeature.geometry.coordinates[0];
    this.latitude = geocodeFeature.geometry.coordinates[1];
    this.category = geocodeFeature.properties.category;
  }
}