import React, { useEffect, useRef } from 'react';
import * as L from 'leaflet';

export const useMarker = (mapContainer: React.RefObject<HTMLDivElement>, map: React.MutableRefObject<L.Map | null>, coordinates?: number[]) => {
  const marker = useRef<L.Marker | null>(null);

  const divIcon = L.divIcon({
    className: 'map-marker',
    html: '<i class="fa-solid fa-location-dot fa-2xl"></i>',
  });

  const setMarker = (marker: React.MutableRefObject<L.Marker | null>, coordinates: number[]) => {
    if (map.current && mapContainer.current && coordinates) {
      if (marker.current) map.current.removeLayer(marker.current);
      marker.current = L.marker(coordinates as [number, number], { icon: divIcon }).addTo(map.current);
    }
  };

  useEffect(() => {
    if (coordinates) setMarker(marker, coordinates);
  }, [coordinates]);
};
