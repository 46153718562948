import React, { useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useFerryRoutes } from '../../hooks/useFerryRoutes';
import { useMap } from '../../hooks/useMap';
import { useMarker } from '../../hooks/useMarker';
import { useRoutes } from '../../hooks/useRoutes';
import { useTolls } from '../../hooks/useTolls';
import { useTripCost } from '../../hooks/useTripCost';
import './trip-map.scss';
import 'leaflet/dist/leaflet.css';

interface TripMapProps {
  startPointCoordinates?: number[];
  destinationCoordinates?: number[];
  distance: string,
  setDistance: (distance: string) => void,
  tollCost?: number,
  setTollCost?: (tollCost: number) => void,
  ferryCost?: number,
  setFerryCost?: (ferryCost: number) => void,
}

export const TripMap: React.FC<TripMapProps> = (
  {
    startPointCoordinates,
    destinationCoordinates,
    distance,
    setDistance,
    tollCost,
    setTollCost,
    ferryCost,
    setFerryCost,
  },
) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const { map, isDarkMode, toggleDarkMode } = useMap(mapContainer);
  const routes = useRoutes(setDistance, map, mapContainer, startPointCoordinates, destinationCoordinates);
  const tolls = useTolls();
  const ferryRoutes = useFerryRoutes();
  const location = useLocation();
  const onTripDetails = location.pathname.startsWith('/reise');
  useMarker(mapContainer, map, startPointCoordinates);
  useMarker(mapContainer, map, destinationCoordinates);
  useTripCost(routes, ferryRoutes, setFerryCost, tolls, setTollCost);

  return (
    <div
      className={`map-container ${destinationCoordinates || startPointCoordinates ? 'show' : ''} ${onTripDetails ? ' trip-details' : ''}`}>
      <div ref={mapContainer} className={`map ${onTripDetails ? 'trip-details' : ''}`} />
      <div className="map-info">
        <div>
          <div className="map-distance">{distance && `Estimert avstand: ${distance} km`}</div>
          {tollCost !== undefined && tollCost >= 0 &&
            <div className="map-tolls">{`Estimert bompenger: ${tollCost} NOK`}</div>}
          {ferryCost !== undefined && ferryCost >= 0 &&
            <div className="map-ferry">{`Estimert ferjepenger: ${ferryCost} NOK`}</div>}
        </div>
        <button className="map-toggle" onClick={() => toggleDarkMode(isDarkMode)}>
          {distance && (isDarkMode
            ? <i className="fa-solid fa-moon"></i>
            : <i className="fa-solid fa-sun"></i>)
          }
        </button>
      </div>
    </div>
  );
};


