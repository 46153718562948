import { useEffect, useState } from 'react';
import { useAuth } from '../components/AuthProvider/AuthProvider';
import { Reservation } from '../models/Reservation';
import { Trip } from '../models/Trip';
import { createRating, fetchRating } from '../services/RatingService';

export const useRating = (newRating: number, trip: Trip, reservation?: Reservation) => {
  const { token } = useAuth();
  const [error, setError] = useState<string>();
  const [rating, setRating] = useState(0);

  useEffect(() => {
    const loadRating = async () => {
      if (reservation) {
        try {
          const ratingData = await fetchRating(token, reservation);
          if (ratingData) {
            setRating(ratingData.rating);
          }
          setError('');
        } catch (error) {
          setError('Klarte ikke å hente vurdering');
          console.error(error);
        }
      } else {
        setRating(trip.driver.driverDetails!.rating);
      }
    };
    loadRating();
  }, [rating]);

  const handleSubmit = async () => {
    try {
      const ratingData = await createRating(newRating, token, reservation);
      setRating(ratingData.rating);
      setError('');
    } catch (error) {
      setError('Klarte ikke å vurdere brukeren');
      console.error(error);
    }
  };

  return {
    submitRating: handleSubmit,
    rating: rating,
    error: error,
  };
};
