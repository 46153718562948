import React from 'react';
import { ChatMessage } from '../../models/ChatMessage';
import { User } from '../../models/User';
import { Chat } from '../../services/ChatService';
import { ChatMessageForm, ChatMessageFormValues } from '../ChatMessageForm/ChatMessageForm';
import './chat-message-list.scss';

interface ChatMessageListProps {
  chat?: Chat;
  messages: ChatMessage[];
  messageListRef: React.RefObject<HTMLUListElement>;
  sendMessage: (messageData: ChatMessageFormValues) => void;
  showMessages: boolean;
  handleBackClick: () => void;
  user?: User;
  error?: string;
}

export const ChatMessageList: React.FC<ChatMessageListProps> =
  ({
     chat,
     messages,
     messageListRef,
     sendMessage,
     showMessages,
     handleBackClick,
     user,
     error,
   }) => {
    const senderName = chat?.reservation?.trip.driver.name == user?.name
      ? chat?.reservation?.user.name
      : chat?.reservation?.trip.driver.name;

    if (!showMessages) return null;
    return (
      <div className="chat-message">
        {error && <div className="error-message">{error}</div>}
        <div className="chat-message-header">
          <div className="chat-message-sender">
            {/*TODO: Replace icon with profile picture*/}
            <i className="header-icon fa fa-user-circle-o"
               aria-hidden="true"></i> {chat && senderName}
          </div>
          <div className="chat-message-back">
            <button onClick={handleBackClick} className="chat-message-back-button">
              <i className="fa-solid fa-x"></i>
            </button>
          </div>
        </div>
        <ul className="chat-message-list" ref={messageListRef}>
          {messages.map((messageData, id) => (
            <li key={id} className={`chat-message-item ${messageData.userId === user?.id ? 'own' : ''}`}>
              <div className="chat-message-bubble">{messageData.message}</div>
            </li>
          ))}
        </ul>
        <ChatMessageForm sendMessage={sendMessage} />
      </div>
    );
  };
