import React from 'react';
import './modal.scss'

interface DeleteVehicleModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const DeleteVehicleModal: React.FC<DeleteVehicleModalProps> =
  ({
     isOpen,
     onClose,
     onConfirm,
   }) => {
    if (!isOpen) return null;
    return (
      <div className="modal-overlay">
        <div className="modal">
          <h2 className="modal-title">Bekreft fjerning av kjøretøy</h2>
          <p>Er du sikker på at du vil fjerne kjøretøyet?</p>
          <div className="modal-actions">
            <button onClick={onClose} aria-label="cancel" className="fremtur-btn small cancel-button">
              Avbryt
            </button>
            <button onClick={onConfirm} aria-label="confirm" className="fremtur-btn small confirm-button">
              Ja, fjern kjøretøyet
            </button>
          </div>
        </div>
      </div>
    );
  };

