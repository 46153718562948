export const capitalize = (text: string) =>
  text.toLowerCase().replace(/(^|\s)\S/g, (letter) => letter.toUpperCase());

export const generateRandomString = (length: number): string => {
  const array = new Uint32Array(length / 2);
  window.crypto.getRandomValues(array);
  return Array.from(array, (dec) => ('0' + dec.toString(16)).substr(-2)).join(
    '',
  );
};
