export enum ChattinessLevel {
  NOT_CHATTY = 'NOT_CHATTY',
  BALANCED = 'BALANCED',
  CHATTY = 'CHATTY',
}

export const chattinessLevelOptions = {
  [ChattinessLevel.NOT_CHATTY]: 'Foretrekker stillhet',
  [ChattinessLevel.BALANCED]: 'Kan slå av en prat',
  [ChattinessLevel.CHATTY]: 'Nokså pratsom',
};

export const chattinessLevelFA = {
  [ChattinessLevel.NOT_CHATTY]: 'fa-volume-xmark',
  [ChattinessLevel.BALANCED]: 'fa-volume-low',
  [ChattinessLevel.CHATTY]: 'fa-volume-high',
};
