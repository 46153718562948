import React from 'react';
import { Link } from 'react-router-dom';
import { TripSearchResponse } from '../../models/TripSearchResponse';
import { TripInfo } from '../TripInfo/TripInfo';
import './trip-list.scss';

interface TripListProps {
  trips: TripSearchResponse[];
  showName: boolean;
}

export const TripList: React.FC<TripListProps> = ({ trips, showName }) => (
  <div className="trip-list">
    {trips.map(({ trip, distanceFromStartPoint, distanceFromDestination }) => (
      <Link
        to={`/reise/${trip.id}`}
        key={trip.id}
        className={`trip-card ${trip.availableSeats === 0 ? 'trip-full' : ''}`}
        tabIndex={trip.availableSeats === 0 ? -1 : 0}
      >
        <TripInfo
          trip={trip}
          distanceFromDestination={distanceFromDestination}
          distanceFromStartPoint={distanceFromStartPoint}
          showPrice={true}
          showName={showName}
          showRating={false}
        />
      </Link>
    ))}
  </div>
);
