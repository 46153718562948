import React from 'react';
import { SearchPage } from '../SearchPage/SearchPage';
import './home-page.scss';

export const HomePage = () => (
  <div className="container-fremtur">
    <div className="container-center container-top">
      <h1 className="fremtur-heading">Fremtur</h1>
      <p className="fremtur-paragraph">Sammen på veien, nærmere målet.</p>
    </div>
    <SearchPage />
    <div className="container-center about-container">
      <div className="fremtur-info">

        <div className="fremtur-text">
          <h2>Om Fremtur</h2>
          <h3>
            Fremtur kobler sjåfører med ledige seter til passasjerer som søker
            skyss over hele Norge, og legger til rette for kostnadseffektiv,
            sosial og miljøvennlig reise.
          </h3>
          <p>
            Vår plattform bygger på prinsipper om tillit, fellesskap og bærekraft,
            med mål om å gjøre reiser tilgjengelige for alle. Enten du skal til
            Oslos pulserende gater, de majestetiske fjordene i vest, eller de
            rolige landskapene i nord, bringer Fremtur mennesker sammen og gjør
            reiser til felles eventyr.
          </p>
        </div>
        <div className="fremtur-icon">
        <span className="container-icon">
          <i className="fa-solid fa-car-on"></i>
        </span>
        </div>
      </div>
    </div>
  </div>
);
