import { useEffect, useState } from 'react';
import { Trip } from '../models/Trip';
import { fetchTrip } from '../services/TripService';

export const useFetchTrip = (tripId?: string, token?: string) => {
  const [trip, setTrip] = useState<Trip>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const loadTrip = async () => {
      if (token) {
        try {
          const tripData: Trip = await fetchTrip(token, tripId);
          setTrip(tripData);
        } catch (error) {
          setError('Klarte ikke å hente reisen');
          console.error(error);
        }
      }
    };
    loadTrip();
  }, [token, tripId]);

  return {
    trip: trip,
    tripError: error,
  };
};
