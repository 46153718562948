import React from 'react';
import { Link } from 'react-router-dom';
import { useFetchUser } from '../../hooks/useFetchUser';
import { ContactInfo } from '../ContactInfo/ContactInfo';
import { DriverInfo } from '../DriverInfo/DriverInfo';
import { VehicleList } from '../VehicleList/VehicleList';
import './profile-page.scss';

export const ProfilePage = () => {
  const { user, error } = useFetchUser();
  return (
    <div className="container-fremtur">
      {error && <div className="container-center error-message">{error}</div>}
      {user && <ContactInfo user={user} />}
      {!user?.driverDetails && <RegisterAsDriverButton/>}
      {user?.driverDetails && <DriverInfo driverDetails={user.driverDetails} />}
      {user?.driverDetails && <VehicleList />}
      {user?.driverDetails &&
        <div className="container-center">
          <Link to="/min-side/kjoretoy">
            <button className="fremtur-btn">
              Legg til kjøretøy
            </button>
          </Link>
        </div>
      }
    </div>
  );
};


const RegisterAsDriverButton = () => {
  return (
      <div className="container-center">
        <Link to="/min-side/sjafor">
          <button className="fremtur-btn">Registrer deg som sjåfør</button>
        </Link>
      </div>
  )
}