import * as Yup from 'yup';
import { ChattinessLevel } from '../enums/ChattinessLevel';
import { SmokingPreference } from '../enums/SmokingPreference';

export const driverRegistrationSchema = () =>
  Yup.object().shape({
    driverBio: Yup.string()
      .min(10, 'Teksten om deg må være minst 10 tegn')
      .required('Skriv litt om deg selv'),
    driverLicenceSince: Yup.date()
      .typeError('Oppgi en dato')
      .max(new Date(), 'Førerkortet kan ikke være utstedt frem i tid')
      .required('Oppgi når førerkortet ditt ble utstedt'),

    chattinessLevel: Yup.mixed<ChattinessLevel>()
      .oneOf(Object.values(ChattinessLevel), 'Ikke en gyldig verdi')
      .required('Oppgi hvor pratsom du er'),

    smokingPreference: Yup.mixed<SmokingPreference>()
      .oneOf(Object.values(SmokingPreference), 'Ikke en gyldig verdi')
      .required('Oppgi din røykepreferanse'),
  });
