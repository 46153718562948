import React, { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import DatePicker from 'react-datepicker';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import {
  ChattinessLevel,
  chattinessLevelOptions,
} from '../../enums/ChattinessLevel';
import {
  SmokingPreference,
  smokingPreferenceOptions,
} from '../../enums/SmokingPreference';
import { DriverDetailsRequests } from '../../models/DriverDetailsRequests';
import { driverRegistrationSchema } from '../../schemas/DriverRegistrationSchema';
import { registerUserAsDriver, updateUserAsDriver } from '../../services/UserService';
import { getDriverDetailsDirtyFields } from '../../utilities/FormUtils';
import { useAuth } from '../AuthProvider/AuthProvider';

export const DriverRegistrationPage = () => {
  const { token, user } = useAuth();
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  const schema = useMemo(() => driverRegistrationSchema(), []);
  const formOptions = {
    resolver: yupResolver(schema),
    defaultValues: {
      chattinessLevel: ChattinessLevel.BALANCED,
      smokingPreference: SmokingPreference.NO_SMOKING,
      driverBio: user?.driverDetails?.driverBio,
      driverLicenceSince: user?.driverDetails?.driverLicenceSince,
    },
  };
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm<DriverDetailsRequests>(formOptions);

  useEffect(() => {
    reset(user?.driverDetails);
  }, [user, reset]);

  const onSubmit = async (data: DriverDetailsRequests) => {
    try {
      if (user) {
        if (!user.driverDetails) {
          await registerUserAsDriver(data, token, user);
        } else {
          await updateUserAsDriver(getDriverDetailsDirtyFields(data, user.driverDetails), token, user);
        }
      }
      navigate('/min-side');
    } catch (error) {
      console.error(error);
      setError('Klarte ikke å registrere deg som sjåfør');
    }
  };

  return (
    <div className="container-fremtur">
      <div className="container-center">
        <h1
          className="fremtur-heading">{!user?.driverDetails ? 'Registrer deg som sjåfør' : 'Oppdater sjåførinformasjon'}</h1>
        {error && <div className="error-message">{error}</div>}
        <form className="fremtur-form" onSubmit={handleSubmit(onSubmit)}>
          {!user?.driverDetails &&
            <div className="form-group">
              <label htmlFor="driverLicenceSince">
                <h3>Når ble førerkortet ditt utstedt?</h3>
              </label>
              <Controller
                name="driverLicenceSince"
                control={control}
                render={({ field }) => (
                  <DatePicker
                    maxDate={new Date()}
                    locale="nb"
                    placeholderText="Dato"
                    formatWeekDay={(day: string) => day.substr(0, 3)}
                    onChange={(date: Date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="dd.MM.yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    customInput={
                      <input
                        className="form-input form-input-date"
                        type="text"
                        id="date"
                        placeholder="Dato" />}
                  />
                )}
              />
              {errors.driverLicenceSince && <p className="form-error">{errors.driverLicenceSince.message}</p>}
            </div>}
          <div className="form-group">
            <label htmlFor="driverBio">
              <h3>Skriv litt om deg selv</h3>
            </label>
            <input
              className="form-input"
              type="text"
              id="driverBio"
              {...register('driverBio')}
            />
            {errors.driverBio && <p className="form-error">{errors.driverBio.message}</p>}
          </div>
          <div className="radio-group">
            <h2>Reisepreferanser</h2>
            <h3>Pratsom?</h3>
            {Object.values(ChattinessLevel).map((level) => (
              <div key={level} className="radio-option">
                <input
                  type="radio"
                  value={level}
                  id={`chattiness-${level}`}
                  {...register('chattinessLevel')}
                />
                <label htmlFor={`chattiness-${level}`}>
                  {chattinessLevelOptions[level]}
                </label>
              </div>
            ))}
          </div>

          <div className="radio-group">
            <h3>Tillater røyking?</h3>
            {Object.values(SmokingPreference).map((pref) => (
              <div key={pref} className="radio-option">
                <input
                  type="radio"
                  value={pref}
                  id={`smoking-${pref}`}
                  {...register('smokingPreference')}
                />
                <label htmlFor={`smoking-${pref}`}>
                  {smokingPreferenceOptions[pref]}
                </label>
              </div>
            ))}
          </div>
          <button type="submit" className="fremtur-btn">
            Registrer opplysninger
          </button>
        </form>
      </div>
    </div>
  );
};
