import React, {useMemo, useState} from "react"
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {useNavigate} from 'react-router-dom';
import {Gender, genderOptions} from '../../enums/Gender';
import {ProfileUpdateRequest} from '../../models/ProfileUpdateRequest';
import {profileUpdateSchema} from '../../schemas/ProfileUpdateSchema';
import {updateProfile} from '../../services/UserService';
import {getProfileUpdateDirtyFields} from '../../utilities/FormUtils';
import {useAuth} from '../AuthProvider/AuthProvider';

export const ProfileUpdatePage = () => {
    const navigate = useNavigate();
    const {token, user} = useAuth();
    const [error, setError] = useState<string>();
    const schema = useMemo(() => profileUpdateSchema(), []);
    const formOptions = {
        resolver: yupResolver(schema),
        defaultValues: {
            gender: user?.gender
        }
    };

    const {
        register,
        handleSubmit
    } = useForm<ProfileUpdateRequest>(formOptions);

    const onSubmit = async (data: ProfileUpdateRequest) => {
        try {
            if (user) {
                await updateProfile(getProfileUpdateDirtyFields(data, user), token, user)
            }
            navigate("/min-side")
        } catch (error) {
            console.error(error);
            setError('Klarte ikke oppdatere profil');
        }
    }

    return (
        <div className="container-fremtur">
            <div className="container-center">
                <h1 className="fremtur-heading">Oppdater profil</h1>
                {error && <div className="error-message">{error}</div>}
                {user &&
                    <form className="fremtur-form" onSubmit={handleSubmit(onSubmit)}>
                        <div className="radio-group">
                            <h3>Kjønn</h3>
                            {
                                Object.values(Gender).map(gender => (
                                    <div key={gender} className="radio-option">
                                        <input
                                            id={`gender-${gender}`}
                                            type="radio"
                                            value={gender}
                                            {...register('gender')}
                                        />
                                        <label htmlFor={`gender-${gender}`}>{genderOptions[gender]}</label>
                                    </div>
                                ))
                            }
                        </div>
                        <button type="submit" className="fremtur-btn">Oppdater profil</button>
                    </form>
                }
            </div>
        </div>
    )
}