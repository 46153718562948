import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Trip } from '../../models/Trip';
import { fetchTripsOnUser } from '../../services/UserService';
import { useAuth } from '../AuthProvider/AuthProvider';
import { ReservationList } from '../ReservationList/ReservationList';
import { TripList } from '../TripList/TripList';
import { UpcomingTripsList } from '../UpcomingTripsList/UpcomingTripsList';
import './trips-page.scss';

const toTripSearchResponse = (trips: Trip[]) => trips.map(trip => ({ trip }));

enum View {
  Passenger,
  Driver
}

export const TripsPage = () => {
  const [view, setView] = useState<View>(View.Passenger);
  const [trips, setTrips] = useState<Trip[]>();
  const { user, token } = useAuth();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const loadTrips = async () => {
      try {
        const tripData = await fetchTripsOnUser(token, user);
        setTrips(tripData);
        setError('');
      } catch (error) {
        setError('Klarte ikke å hente reiser på bruker');
        console.error(error);
      }
    };
    if (token && user) {
      loadTrips();
    }
  }, [user]);


  return (
    <div className="container-fremtur">
      {error && <div className="container-center error-message">{error}</div>}
      <h1 className="fremtur-heading">Reiser</h1>
      {user?.driverDetails &&
        <div className="container-center">
          <div className="trips-page-toggle-buttons">
            <button
              onClick={() => setView(View.Passenger)}
              className={`fremtur-btn toggle ${view === View.Passenger ? 'active' : ''}`}
            >
              Passasjer – Kommende reiser
            </button>
            <button
              onClick={() => setView(View.Driver)}
              className={`fremtur-btn toggle ${view === View.Driver ? 'active' : ''}`}
            >
              Sjåfør – Reiser og reservasjoner
            </button>
          </div>
        </div>}
      {view === View.Passenger && <div className="container-center">
        <h2 className="fremtur-heading">Kommende reiser</h2>
        <UpcomingTripsList />
      </div>}
      {view === View.Driver && user?.driverDetails && trips && trips.length !== 0 &&
        <div className="container-center">
          <h2 className="fremtur-heading">Reservasjoner</h2>
          <ReservationList trips={trips} />
        </div>
      }
      {view === View.Driver && user?.driverDetails && (
        <div className="container-center">
          <h2 className="fremtur-heading">Mine reiser</h2>
          {trips &&
            (trips.length === 0 ? (
                <h3>Du har ikke lagt til noen reiser. Opprett en ny reise <Link to="/reise/opprett">her</Link>
                  .</h3>
              ) : (
                <TripList trips={toTripSearchResponse(trips)} showName={false} />)
            )}
        </div>)}
    </div>
  );
};


