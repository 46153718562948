import React, { useState } from 'react';
import { chattinessLevelOptions } from '../../enums/ChattinessLevel';
import { smokingPreferenceOptions } from '../../enums/SmokingPreference';
import { Trip } from '../../models/Trip';
import { TripInfo } from '../TripInfo/TripInfo';
import { TripMap } from '../TripMap/TripMap';
import './trip-info-card.scss';

interface TripInfoProps {
  trip: Trip;
}

export const TripInfoCard: React.FC<TripInfoProps> = ({ trip }) => {
  const [distance, setDistance] = useState<string>('');
  const from = [trip.startPoint.latitude, trip.startPoint.longitude];
  const to = [trip.destination.latitude, trip.destination.longitude];
  return (
    <div className="trip-list">
      <div className="trip-card">
        <TripInfo trip={trip} showName={true} />
        <div className="trip-driver-info">
          <div className="trip-driver-info-row">
            <span className="driver-info-label">Alder:</span>
            <span className="driver-info-data">{trip.driver.age} år</span>
          </div>
          <div className="trip-driver-info-row">
            <span className="driver-info-label">Beskrivelse:</span>
            <span className="driver-info-data">{trip.driver.driverDetails!.driverBio}</span>
          </div>
          <div className="trip-driver-info-row">
            <span className="driver-info-label">Pratenivå:</span>
            <span
              className="driver-info-data">{chattinessLevelOptions[trip.driver.driverDetails!.chattinessLevel]}</span>
          </div>
          <div className="trip-driver-info-row">
            <span className="driver-info-label">Røykepreferanse:</span>
            <span
              className="driver-info-data">{smokingPreferenceOptions[trip.driver.driverDetails!.smokingPreference]}</span>
          </div>
        </div>
        <div className="trip-divider"></div>
        <div className="trip-details-row">
          <div className="trip-text">Kjøretøy:</div>
          <div className="trip-bold">
            {trip.licensePlateNumber} {trip.vehicleDetails.color} {trip.vehicleDetails.brand}
          </div>
        </div>
        <div className="trip-divider"></div>
        <div className="trip-details-row">
          <div className="trip-text">Pris for 1 passasjer:</div>
          <div className="trip-bold">Kr. {trip.reservationPrice},-</div>
        </div>
        <TripMap
          startPointCoordinates={from}
          destinationCoordinates={to}
          distance={distance}
          setDistance={setDistance}
        />
      </div>
    </div>
  );
};
