import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthProvider/AuthProvider';
import './header.scss';

export const Header = () => {
  const { user, isLoggedIn } = useAuth();
  const [isToggled, setIsToggled] = useState<boolean>(false);
  const location = useLocation();

  useEffect(() => {
    setIsToggled(false);
  }, [location]);

  const hamburgerComponent = () => (
    <div className={`hamburger ${isToggled && 'toggled'}`} onClick={() => setIsToggled(!isToggled)}>
      <div className={`hamburger-bar top ${isToggled && 'toggled'}`}></div>
      <div className={`hamburger-bar middle ${isToggled && 'toggled'}`}></div>
      <div className={`hamburger-bar bottom ${isToggled && 'toggled'}`}></div>
    </div>
  );

  const chatComponent = () => (
    <Link className="header-nav-item" to="/meldinger">
      Meldinger
    </Link>
  );
  const createTripComponent = () => (
    <Link reloadDocument className="header-nav-item" to="/opprett-reise">
      Opprett reise
    </Link>
  );
  const registerPageComponent = () => (
    <Link className="header-nav-item" to="/registrer">
      Registrer deg
    </Link>
  );
  const profilePageComponent = () => (
    <Link className="header-nav-item" to="/min-side">
      Min side
    </Link>
  );
  const tripsPageComponent = () => (
    <Link className="header-nav-item" to="/reiser">
      Mine reiser
    </Link>
  );

  const logoComponent = () => (
    <Link className="logo" to="/">
      Fremtur.
    </Link>
  );

  return (
    <header>
      <div className="header-bar">
        <div className="header-logo">
          {logoComponent()}
        </div>
        {hamburgerComponent()}
      </div>
      <div className={`header-navigation ${isToggled && 'toggled'}`}>
        {isLoggedIn && chatComponent()}
        {isLoggedIn && user?.driverDetails && createTripComponent()}
        {isLoggedIn && profilePageComponent()}
        {isLoggedIn && tripsPageComponent()}
        {!isLoggedIn && registerPageComponent()}
      </div>
    </header>
  );
};
