import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { VehicleRequest } from '../../models/VehicleRequest';
import { registerVehicleOnUser } from '../../services/UserService';
import { useAuth } from '../AuthProvider/AuthProvider';
import './vehicle-registration-page.scss'

export const VehicleRegistrationPage = () => {
  const { token, user } = useAuth();
  const [error, setError] = useState<string>();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<VehicleRequest>();

  const onSubmit = async (data: VehicleRequest) => {
    try {
      if (user && user.driverDetails) {
        await registerVehicleOnUser(data, token, user);
      }
      navigate('/min-side');
    } catch (error) {
      console.error(error);
      setError('Klarte ikke å registrere kjøretøy');
    }
  };

  return (
    <div className="container-fremtur">
      <div className="container-center">
        <h1
          className="fremtur-heading">
          Legg til kjøretøy
        </h1>
        {error && <div className="error-message">{error}</div>}
        <form className="vehicle-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="vehicle-form-group">
            <label htmlFor="licensePlateNumber">
              <h3>Registreringsnummer eller personlig skilt
              </h3>
            </label>
            <input
              className="form-input"
              type="text"
              id="licensePlateNumber"
              placeholder="F.eks. AB-12345"
              {...register('licensePlateNumber')}
            />
            {errors.licensePlateNumber && <p className="form-error">{errors.licensePlateNumber.message}</p>}
          </div>
          <button type="submit" className="fremtur-btn">
            Hent opplysninger
          </button>
        </form>
      </div>
    </div>
  );
};