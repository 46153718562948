import React from 'react';
import {QueryClient, QueryClientProvider} from 'react-query';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import {AuthProvider} from './components/AuthProvider/AuthProvider';
import {CallbackPage} from './components/CallbackPage/CallbackPage';
import {ChatPage} from './components/ChatPage/ChatPage';
import {CreateTripPage} from './components/CreateTripPage/CreateTripPage';
import {DriverRegistrationPage} from './components/DriverRegistrationPage/DriverRegistrationPage';
import {Footer} from './components/Footer/Footer';
import {Header} from './components/Header/Header';
import {HomePage} from './components/HomePage/HomePage';
import {PassengerRatingPage} from './components/PassengerRatingPage/PassengerRatingPage';
import {ProfilePage} from './components/ProfilePage/ProfilePage';
import {ProfileUpdatePage} from './components/ProfileUpdatePage/ProfileUpdatePage';
import {ProtectedRoute} from './components/ProtectedRoute/ProtectedRoute';
import {PublicProfilePage} from './components/PublicProfilePage/PublicProfilePage';
import {RegisterPage} from './components/RegisterPage/RegisterPage';
import {ReservationConfirmationPage} from './components/ReservationConfirmationPage/ReservationConfirmationPage';
import {SearchPage} from './components/SearchPage/SearchPage';
import {TripInfoPage} from './components/TripInfoPage/TripInfoPage';
import {TripsPage} from './components/TripsPage/TripsPage';
import {VehicleRegistrationPage} from './components/VehicleRegistrationPage/VehicleRegistrationPage';
import './App.scss';

function App() {
    return (
        <QueryClientProvider client={new QueryClient()}>
            <BrowserRouter>
                <AuthProvider>
                    <Header/>
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path="/registrer" element={<RegisterPage/>}/>
                        <Route path="/callback" element={<CallbackPage/>}/>
                        <Route path="/search" element={<SearchPage/>}/>
                        <Route element={<ProtectedRoute/>}>
                            <Route path="/meldinger" element={<ChatPage/>}/>
                            <Route path="/min-side" element={<ProfilePage/>}/>
                            <Route path="/min-side/sjafor" element={<DriverRegistrationPage/>}/>
                            <Route path="/min-side/kjoretoy" element={<VehicleRegistrationPage/>}/>
                            <Route path="/min-side/oppdater-profil" element={<ProfileUpdatePage/>}/>
                            <Route path="/reiser" element={<TripsPage/>}/>
                            <Route path="/reise/:tripId" element={<TripInfoPage/>}/>
                            <Route path="/opprett-reise" element={<CreateTripPage/>}/>
                            <Route path="/bruker/offentlig/:userId" element={<PublicProfilePage/>}/>
                            <Route path="/bruker/offentlig/:userId/passasjer-vurdering" element={<PassengerRatingPage/>}/>
                            <Route
                                path="/reise/:tripId/reserver"
                                element={<ReservationConfirmationPage/>}
                            />
                        </Route>
                    </Routes>
                    <Footer/>
                </AuthProvider>
            </BrowserRouter>
        </QueryClientProvider>
    );
}

export default App;
