import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchUser } from '../../services/UserService';
import { fetchToken } from '../../services/VippsService';
import { useAuth } from '../AuthProvider/AuthProvider';


export const CallbackPage = () => {
  const { login, isLoggedIn } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const loginUser = async () => {
      const queryParams = new URLSearchParams(location.search);
      const code = queryParams.get('code');
      try {
        const tokenData = await fetchToken(code);
        const userData = await fetchUser(tokenData.idToken);
        login(tokenData.idToken, userData);
        setError('');
      } catch (error) {
        setError('Klarte ikke å finne bruker');
        console.error(error);
      }
    };

    loginUser();
  }, [location.search]);

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/min-side');
    }
  }, [isLoggedIn, navigate]);

  return (
    <div className="container-fremtur">
      <div className="container-center">
        <h1 className="fremtur-heading">Logger inn...</h1>
        {error && <div className="error-message">{error}</div>}
      </div>
    </div>
  );
};
