import { DriverDetailsRequests, DriverDetailsUpdateRequest } from '../models/DriverDetailsRequests';
import {ProfileUpdateRequest} from '../models/ProfileUpdateRequest';
import {DriverDetails, User} from '../models/User';

export const getDriverDetailsDirtyFields = (data: DriverDetailsRequests, driverDetails: DriverDetails) => {
  const validKeys: (keyof DriverDetailsUpdateRequest)[] = ['driverBio', 'chattinessLevel', 'smokingPreference'];
  return validKeys.reduce<DriverDetailsUpdateRequest>((acc, key) => {
    const fieldKey = key as keyof DriverDetailsUpdateRequest;

    if (data[fieldKey] && data[fieldKey] !== driverDetails[fieldKey]) {
      // @ts-expect-error Is not undefined
      acc[fieldKey] = data[fieldKey];
    }
    return acc;
  }, {} as DriverDetailsUpdateRequest);
};

export const getProfileUpdateDirtyFields = (data: ProfileUpdateRequest, user: User) => {
  const validKeys: (keyof ProfileUpdateRequest)[] = ['gender'];
  return validKeys.reduce<ProfileUpdateRequest>((acc, key) => {
    const fieldKey = key as keyof ProfileUpdateRequest;

    if (data[fieldKey] && data[fieldKey] !== user[fieldKey]) {
      acc[fieldKey] = data[fieldKey];
    }
    return acc;
  }, {} as ProfileUpdateRequest);
}
