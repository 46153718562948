import React from 'react';
import {Link} from 'react-router-dom';
import {chattinessLevelFA, chattinessLevelOptions,} from '../../enums/ChattinessLevel';
import {smokingPreferenceFA, smokingPreferenceOptions} from '../../enums/SmokingPreference';
import { DriverDetails } from '../../models/User';
import { formatDateToReadableDateWithYear } from '../../utilities/DateUtils';
import './driver-info.scss';

export const DriverInfo = ({driverDetails}: {
  driverDetails: DriverDetails;
}) => {
  return (<div className="container-center">
      <h1 className="fremtur-heading">Sjåførinformasjon</h1>
      <div className="driver-info-card">
        <div className="driver-info-row space-between">
          <div>
            <i className="fa fa-circle-info driver-info-icon"></i>
            <span className="driver-info-label">Sjåførbeskrivelse:</span>
            <span className="driver-info-data">{driverDetails.driverBio}</span>
          </div>
          <div>
            <Link to="/min-side/sjafor">
              <i className="fa-solid fa-edit edit-icon"/>
            </Link>
          </div>
        </div>
        <div className="driver-info-row">
          <i className="fa fa-star-half-stroke driver-info-icon"></i>
          <span className="driver-info-label">Vurdering:</span>
          <span className="driver-info-data">
          {driverDetails.rating !== 0
            ? `${driverDetails.rating} / 5 ★`
            : 'Du har ikke blitt vurdert'}
        </span>
        </div>
        <div className="driver-info-row">
          <i className="fa fa-id-card driver-info-icon"></i>
          <span className="driver-info-label">Førerkort siden:</span>
          <span className="driver-info-data">
          {formatDateToReadableDateWithYear(new Date(driverDetails.driverLicenceSince))}
        </span>
        </div>
        <div className="driver-info-row">
          <i className={`fa ${chattinessLevelFA[driverDetails.chattinessLevel]} driver-info-icon`}></i>
          <span className="driver-info-label">Pratenivå:</span>
          <span className="driver-info-data">{chattinessLevelOptions[driverDetails.chattinessLevel]}</span>
        </div>
        <div className="driver-info-row">
          <i className={`fa ${smokingPreferenceFA[driverDetails.smokingPreference]} driver-info-icon`}></i>
          <span className="driver-info-label">Røykepreferanse:</span>
          <span className="driver-info-data">{smokingPreferenceOptions[driverDetails.smokingPreference]}</span>
        </div>
      </div>
    </div>
  );
};