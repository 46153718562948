import { useState } from 'react';
import { Trip } from '../models/Trip';
import { User } from '../models/User';
import { Vehicle } from '../models/Vehicle';
import { VehicleRequest } from '../models/VehicleRequest';
import { createTrip, TripFormState } from '../services/TripService';
import { fetchVehiclesOnUser, registerVehicleOnUser } from '../services/UserService';

export const useOnTripSubmit = (vehicles: Vehicle[], handleNextStep: () => void, reset: () => void, token?: string, user?: User) => {
  const [error, setError] = useState<string>();
  const [addedTrip, setAddedTrip] = useState<Trip>();

  const fetchVehicleId = async (data: TripFormState) => {
    const vehicleData = await fetchVehiclesOnUser(user, token);
    const vehicleId = vehicleData
      .find((vehicle) => vehicle.licensePlateNumber === data.vehicleNumber)
      ?.id;
    if (!vehicleId) {
      throw new Error(`Finner ikke kjøretøy med registreringsnummer ${data.vehicleNumber}`);
    }
    return vehicleId;
  };

  const onSubmit = async (data: TripFormState) => {
    try {
      if (vehicles.length === 0) {
        const vehicleRequest: VehicleRequest = {
          licensePlateNumber: data.vehicleNumber,
        };
        await registerVehicleOnUser(vehicleRequest, token, user);
        const vehicleId = await fetchVehicleId(data);
        data = { ...data, vehicleNumber: vehicleId };
      }
      const tripData = await createTrip(data, token, user);
      setAddedTrip(tripData);
      reset();
      await handleNextStep();
      setError('');
    } catch (error) {
      setError('Klarte ikke å registrere reisen');
      console.error(error);
    }
  };

  return {
    onSubmit: onSubmit,
    addedTrip: addedTrip,
    createTripError: error,
  };
};
