import { Reservation } from '../models/Reservation';
import { Chat } from '../services/ChatService';

export const filterAndSetTripOnChats = (reservationsOnUser: Reservation[], reservationsOnTrip: Reservation[], chats: Chat[]) => {
  const mergedReservationsMap = new Map();
  reservationsOnUser.forEach(res => mergedReservationsMap.set(res.id, res));
  reservationsOnTrip.forEach(res => mergedReservationsMap.set(res.id, res));
  const mergedReservations = Array.from(mergedReservationsMap.values());

  const updatedChats = chats.map(chat => {
    const reservation = mergedReservations.find(res => res.id === chat.reservationId);
    return reservation ? { ...chat, reservation } : chat;
  });

  const filteredReservations = mergedReservations.filter(reservation =>
    !updatedChats.some(chat => chat.reservationId === reservation.id),
  );

  return { updatedChats, filteredReservations };
};
