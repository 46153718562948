export interface VippsTokenResponse {
  idToken: string;
  expiresIn: number;
}

export const fetchToken = async (
  code: string | null,
): Promise<VippsTokenResponse> => {
  if (!code) throw new Error('No authentication code found');

  const requestBody = { code: code };
  const response = await fetch(`/v1/vipps/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(requestBody),
  });

  if (!response.ok)
    throw new Error(
      `Error during authentication: ${response.status} ${response.statusText}`,
    );
  return response.json();
};
