import React from 'react';
import { useFormContext } from 'react-hook-form';
import './slider.scss';
import { formatDistance } from '../../utilities/NumberUtils';

interface SliderProps {
  min: number;
  max: number;
  label: string;
}

export const Slider: React.FC<SliderProps> = ({ min, max, label }) => {
  const { register, setValue, watch } = useFormContext();
  const value = watch(label);

  return (
    <div className={`slider-container`}>
      <div className="slider-input">
        <input
          className={`slider`}
          type="range"
          min={min}
          max={max}
          step={200}
          {...register(label)}
          onChange={(e) => setValue(label, Number(e.target.value))}
        />
      </div>
      <div className="slider-info">
        <span>Søkeradius: {formatDistance(Number(value))}</span>
      </div>
    </div>
  );
};

