import { useEffect, useRef, useState } from 'react';
import { Chat } from '../services/ChatService';
import { useMessages } from './useMessages';

export const useWebSocket = (page: number, token?: string, ticket?: string, chat?: Chat) => {
  const socketRef = useRef<WebSocket | null>(null);
  const wsUrl = process.env.REACT_APP_WEBSOCKET_URL || '';
  const { messages, setMessages, messageError } = useMessages(page, token, chat);
  const [error, setError] = useState<string>();

  useEffect(() => {
    if (!chat || !token || !ticket) return;
    const socket = new WebSocket(`${wsUrl}?ticket=${ticket}&chatId=${chat.id}`);

    socketRef.current = socket;

    socket.onmessage = function(event) {
      const message = JSON.parse(event.data);
      try {
        setMessages(prevMessages => [...prevMessages, message]);
        setError('')
      } catch (error) {
        setError('Klarte ikke å lese meldinger')
        console.error(error);
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    return () => {
      socket.close();
    };
  }, [ticket]);

  return {
    messages: messages,
    setMessages: setMessages,
    socket: socketRef,
    wsError: error,
    messageError: messageError
  };
};
