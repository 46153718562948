import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { useFetchVehicles } from '../../hooks/useFetchVehicles';
import { Vehicle } from '../../models/Vehicle';
import { deleteVehicleOnUser, updateVehicleOnUser } from '../../services/UserService';
import { useAuth } from '../AuthProvider/AuthProvider';
import { DeleteVehicleModal } from '../Modals/DeleteVehicleModal';
import './vehicle-list.scss';

interface EditVehicleFormInputs {
  color: string;
  brand: string;
}


export const VehicleList = () => {
  const { user, token } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle>();
  const [editingVehicle, setEditingVehicle] = useState<Vehicle>();
  const { register, handleSubmit, reset } = useForm<EditVehicleFormInputs>();
  const { loadVehicles, vehicles, vehicleError } = useFetchVehicles(user, token);
  const [error, setError] = useState<string>();

  const openModal = (vehicle: Vehicle): void => {
    setIsModalOpen(true);
    setSelectedVehicle(vehicle);
  };

  const closeModal = (): void => {
    setIsModalOpen(false);
    setSelectedVehicle(undefined);
  };

  const startEditing = (vehicle: Vehicle): void => {
    setEditingVehicle({ ...vehicle });
    reset({
      color: vehicle.color,
      brand: vehicle.brand,
    });
  };

  const onSubmit = async (data: EditVehicleFormInputs) => {
    if (editingVehicle) {
      const updatedVehicle = { ...editingVehicle, ...data };
      try {
        await updateVehicleOnUser(updatedVehicle, token, user);
        setEditingVehicle(undefined);
        await loadVehicles();
        setError('');
      } catch (error) {
        setError('Klarte ikke å oppdatere kjøretøyet');
        console.error(error);
      }
    }
  };

  const deleteVehicle = async () => {
    if (selectedVehicle) {
      try {
        await deleteVehicleOnUser(selectedVehicle, token, user);
        closeModal();
        await loadVehicles();
        setError('');
      } catch (error) {
        setError('Klarte ikke å fjerne kjøretøyet');
        console.error(error);
      }
    }
  };

  return (
    <div className="container-center">
      {error && <div className="error-message">{error}</div>}
      {vehicleError && <div className="error-message">{vehicleError}</div>}
      <h1 className="fremtur-heading">Kjøretøy</h1>
      {vehicles?.length === 0
        ?
        <h3>
          Finner ingen registrerte kjøretøy. Du kan registrere et nytt kjøretøy <Link to="/min-side/kjoretoy">
          her</Link>.
        </h3>

        : <div className="vehicle-list">
          {vehicles && vehicles.map((vehicle) => (
            <div key={vehicle.id} className="vehicle-info-card">
              <div className="vehicle-info-row">
                <div className="vehicle-info-col">
                  <i className="fa fa-car vehicle-info-icon"></i>
                  <span className="vehicle-info-label">
                    Kjøretøy:
                  </span>
                  {editingVehicle && editingVehicle.id === vehicle.id ? (
                    <form className="vehicle-info" onSubmit={handleSubmit(onSubmit)}>
                      <input
                        type="text"
                        className="vehicle-form-input color"
                        {...register('color', { required: true })}
                        defaultValue={vehicle.color}
                      />
                      <input
                        type="text"
                        className="vehicle-form-input brand"
                        {...register('brand', { required: true })}
                        defaultValue={vehicle.brand}
                      />
                    </form>
                  ) : (
                    <div className="vehicle-info">
                      <span className="vehicle-info-data">{vehicle.color} {vehicle.brand}</span>
                    </div>
                  )}
                </div>
                <div className="vehicle-info-col">
                  {editingVehicle && editingVehicle.id === vehicle.id ? (
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <button type="submit" aria-label="accept"
                              className="vehicle-floating save clickable">
                        <i className="fa-solid fa-check"/>
                      </button>
                    </form>
                  ) : (
                    <>
                      <i className="fa-solid fa-edit edit-icon" aria-label="edit-icon" onClick={() => startEditing(vehicle)}/>
                      <i className="fa-solid fa-xmark cancel-icon margin-left" aria-label="delete-icon" onClick={() => openModal(vehicle)}/>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      }
      <DeleteVehicleModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onConfirm={deleteVehicle}
      />
    </div>
  );

};
