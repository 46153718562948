import React from 'react';
import "./ReservationConfirmationPage.scss"

interface PassengersCountProps {
    availableSeats: number
    passengersCount: number,
    setPassengersCount: (count: number) => void
}

export const PassengersCount = ({
    availableSeats,
    passengersCount,
    setPassengersCount
}: PassengersCountProps) => {
    const updatePassengersCount = (passengers: number) => {
        const newPassengersCount = passengersCount + passengers
        if (newPassengersCount < 1 || passengersCount > availableSeats) return
        setPassengersCount(newPassengersCount)
    }

    return (
        <>
            <div className="reservation-row-center">
                Hvor mange passasjerer ønsker du å reservere for?
            </div>
            <div className="reservation-row-center">
                <button
                    className="fremtur-btn increment"
                    disabled={passengersCount === 1}
                    onClick={() => updatePassengersCount(-1)}
                >-
                </button>
                {passengersCount}
                <button
                    className="fremtur-btn increment"
                    disabled={passengersCount === availableSeats}
                    onClick={() => updatePassengersCount(1)}
                >+
                </button>
            </div>
        </>
    )
}