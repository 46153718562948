import React, { useEffect, useState } from 'react';
import { useFetchChats } from '../../hooks/useFetchChats';
import { useScrollMessages } from '../../hooks/useScrollMessages';
import { useWebSocket } from '../../hooks/useWebSocket';
import { Chat, createChat, fetchTicket } from '../../services/ChatService';
import { useAuth } from '../AuthProvider/AuthProvider';
import { ChatList } from '../ChatList/ChatList';
import { ChatMessageFormValues } from '../ChatMessageForm/ChatMessageForm';
import { ChatMessageList } from '../ChatMessageList/ChatMessageList';
import './chat-page.scss';

export const ChatPage = () => {
    const { user, token } = useAuth();
    const [currentChat, setCurrentChat] = useState<Chat>();
    const [ticket, setTicket] = useState<string>();
    const [showMessages, setShowMessages] = useState(false);
    const [page, setPage] = useState<number>(0);
    const { chats, reservations, chatError } = useFetchChats(currentChat, user, token);
    const { messages, setMessages, socket, wsError, messageError } = useWebSocket(page, token, ticket, currentChat);
    const { messageList } = useScrollMessages(messages, page, setPage);

    const performCreateChat = async (reservationId: string) => {
      try {
        const chatData = await createChat(reservationId, token);
        const reservationOnChat = reservations.find(res => res.id == chatData.reservationId);
        const updatedChat: Chat = { ...chatData, reservation: reservationOnChat };
        handleChatClick(updatedChat);
      } catch (error) {
        console.error(error);
      }
    };

    const handleReservationClick = (reservationId: string) => {
      performCreateChat(reservationId);
    };

    const handleChatClick = (chat: Chat) => {
      setCurrentChat(chat);
      setShowMessages(true);
      setPage(0);
    };

    useEffect(() => {
      const loadTicket = async () => {
        try {
          const ticket = await fetchTicket(token);
          setTicket(ticket);
        } catch (error) {
          console.error(error);
        }
      };
      loadTicket();
    }, [currentChat]);


    const sendMessage = (messageData: ChatMessageFormValues) => {
      if (socket.current) {
        socket.current.send(JSON.stringify(messageData));
      }
    };

    const handleBackClick = () => {
      setShowMessages(false);
      setMessages([]);
      setCurrentChat(undefined);
    };

    return (
      <div className={`chat-container ${showMessages ? 'show-messages' : ''}`}>
        {wsError && <div className="error-message">{wsError}</div>}
        <div className={`chat-list-container ${showMessages ? 'show-messages' : ''}`}>
          <ChatList
            reservations={reservations}
            chats={chats}
            currentChat={currentChat}
            handleReservationClick={handleReservationClick}
            handleChatClick={handleChatClick}
            error={chatError}
          />
        </div>
        <div className={`chat-message-list-container ${showMessages ? 'show-messages' : ''}`}>
          <ChatMessageList
            chat={currentChat}
            messages={messages}
            messageListRef={messageList}
            showMessages={showMessages}
            handleBackClick={handleBackClick}
            sendMessage={sendMessage}
            user={user}
            error={messageError}
          />
        </div>
      </div>
    );
  }
;


