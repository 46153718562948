import { useEffect, useRef } from 'react';
import { ChatMessage } from '../models/ChatMessage';

export const useScrollMessages = (messages: ChatMessage[], page: number, setPage: (value: (((prevState: number) => number) | number)) => void) => {
  const messageListRef = useRef<HTMLUListElement | null>(null);

  const handleScroll = () => {
    const messageListElement = messageListRef.current;
    if (messageListElement && messageListElement.scrollTop === 0) {
      setPage(prevPage => prevPage + 1);
    }
  };

  useEffect(() => {
    const messageListElement = messageListRef.current;
    if (messageListElement) {
      messageListElement.addEventListener('scroll', handleScroll);
      return () => {
        messageListElement.removeEventListener('scroll', handleScroll);
      };
    }
  }, []);

  useEffect(() => {
    const messageListElement = messageListRef.current;
    if (messageListElement && page === 0) {
      messageListElement.scrollTo({
        top: messageListElement.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [messages]);

  return { messageList: messageListRef };
};
