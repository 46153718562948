import { useEffect, useState } from 'react';
import { Reservation } from '../models/Reservation';
import { User } from '../models/User';
import { Chat, fetchChatsOnUser } from '../services/ChatService';
import { fetchReservationsOnTrip } from '../services/TripService';
import { fetchReservationsOnUser, fetchTripsOnUser } from '../services/UserService';
import { filterAndSetTripOnChats } from '../utilities/ArrayUtils';

export const useFetchChats = (chat?: Chat, user?: User, token?: string) => {
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [chats, setChats] = useState<Chat[]>([]);
  const [error, setError] = useState<string>()

  const loadReservationsOnTrip = async () => {
    try {
      const tripData = await fetchTripsOnUser(token, user);
      const reservationPromises = tripData.map(trip => fetchReservationsOnTrip(trip.id, token));
      const allReservations = await Promise.all(reservationPromises);
      return allReservations.reduce((prev, curr) => prev.concat(curr), []);
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const loadReservationsAndChats = async () => {
    try {
      const reservationsOnUser = await fetchReservationsOnUser(token, user);
      const reservationsOnTrip = await loadReservationsOnTrip();
      const chatData = await fetchChatsOnUser(token, user);
      const {
        updatedChats,
        filteredReservations,
      } = filterAndSetTripOnChats(reservationsOnUser, reservationsOnTrip, chatData);
      setChats(updatedChats.reverse());
      setReservations(filteredReservations.reverse());
      setError('')
    } catch (error) {
      setError('Klarte ikke å laste inn samtaler')
      console.error(error);
    }
  };

  useEffect(() => {
    if (user) {
      loadReservationsAndChats();
    }
  }, [chat, user]);

  return {
    chats: chats,
    reservations: reservations,
    chatError: error
  };
};

