import { useEffect, useState } from 'react';
import { User } from '../models/User';
import { Vehicle } from '../models/Vehicle';
import { fetchVehiclesOnUser } from '../services/UserService';

export const useFetchVehicles = (user?: User, token?: string) => {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [error, setError] = useState<string>();
  const loadVehicles = async () => {
    try {
      const vehicleData = await fetchVehiclesOnUser(user, token);
      setVehicles(vehicleData);
      setError('');
    } catch (error) {
      setError('Klarte ikke å hente kjøretøy');
      console.error(error);
    }
  };

  useEffect(() => {
    if (token && user) {
      loadVehicles();
    }
  }, [user, token]);

  return {
    loadVehicles: loadVehicles,
    vehicles: vehicles,
    vehicleError: error,
  };
};
