import { useEffect, useState } from 'react';
import { fetchTollData } from '../services/TripPlannerService';

export interface TollData {
  id: number,
  tags: { [key: string]: string }
}

export const useTolls = () => {
  const [tolls, setTolls] = useState<TollData[]>();
  useEffect(() => {
    const loadTolls = async () => {
      try {
        const tollData = await fetchTollData();
        setTolls(tollData.elements);
      } catch (error) {
        console.error(error);
      }
    };
    loadTolls();
  }, []);

  return tolls;
};

