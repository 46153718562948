import React from 'react';
import { generateRandomString } from '../../utilities/StringUtils';

const generateVippsAuthUrl = (): string => {
  const state = generateRandomString(16);
  const clientId = process.env.REACT_APP_LOGIN_CLIENT_ID || '';
  const redirectUri = encodeURIComponent(
    process.env.REACT_APP_LOGIN_REDIRECT_URI || '',
  );
  const scope = encodeURIComponent('openid name email phoneNumber birthDate');
  const responseType = 'code';
  const vippsUrl = 'https://apitest.vipps.no';
  return `${vippsUrl}/access-management-1.0/access/oauth2/auth?client_id=${clientId}&response_type=${responseType}&scope=${scope}&state=${state}&redirect_uri=${redirectUri}`;
};

export const RegisterPage = () => {
  const vippsAuthUrl = generateVippsAuthUrl();

  return (
    <div className="container-fremtur">
      <div className="container-center">
        <h1 className="fremtur-heading">Registrer deg med Vipps</h1>
        <p>
          Opprett en konto raskt ved å bruke Vipps-appen din. Det er raskt,
          sikkert og enkelt.
        </p>
        <a href={vippsAuthUrl}>
          <vipps-mobilepay-button
            type="button"
            brand="vipps"
            language="no"
            variant="primary"
            rounded="false"
            verb="register"
            stretched="false"
            branded="true"
            loading="false"
          ></vipps-mobilepay-button>
        </a>
      </div>
    </div>
  );
};
