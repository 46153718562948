import { useEffect, useState } from 'react';
import { useAuth } from '../components/AuthProvider/AuthProvider';
import { fetchUser } from '../services/UserService';

export const useFetchUser = () => {
  const { user, token, updateUserContext } = useAuth();
  const [error, setError] = useState<string>();

  useEffect(() => {
    const reloadUser = async () => {
      try {
        const userData = await fetchUser(token);
        updateUserContext(userData);
      } catch (error) {
        setError('Klarte ikke å hente brukerinfo');
        console.error(error);
      }
    };
    reloadUser();
  }, [token]);

  return {
    user,
    error,
  };
};
