import React, { useEffect, useRef, useState } from 'react';
import * as L from 'leaflet';
import 'leaflet-routing-machine';
import { Routing } from 'leaflet';
import { fetchTripRoutes } from '../services/TripPlannerService';
import { convertMeterToKilometer } from '../utilities/NumberUtils';


interface Leg {
  annotation: {
    nodes: number[]
  };
}

export interface Route {
  legs: Leg[];
}

interface RouteFound {
  summary: {
    totalDistance: number,
    totalTime: number
  };
}

interface RoutesFound {
  routes: RouteFound[];
}

export const useRoutes = (setDistance: (distance: string) => void, map: React.MutableRefObject<L.Map | null>, mapContainer: React.MutableRefObject<HTMLDivElement | null>, startPointCoordinates?: number[], destinationCoordinates?: number[]) => {
  const [routes, setRoutes] = useState<Route[]>();
  const control = useRef<L.Control | null>(null);
  const lineStyles = [
    { color: '#000000', opacity: 1, weight: 6 },
    { color: '#f9c767', opacity: 1, weight: 4 },
  ];

  const estimateDistance = async (routesFound: RoutesFound) => {
    if (routesFound.routes.at(0)) {
      const distanceInMeters = routesFound.routes.at(0)!.summary.totalDistance;
      const distanceInKilometers = convertMeterToKilometer(distanceInMeters);
      setDistance(distanceInKilometers);
    }
  };

  const findTrip = () => {
    if (map.current && mapContainer.current && destinationCoordinates && startPointCoordinates) {
      const from = startPointCoordinates as [number, number];
      const to = destinationCoordinates as [number, number];
      map.current.fitBounds([from, to],
        {
          paddingTopLeft: [25, 25],
          paddingBottomRight: [25, 25],
        },
      );

      if (control.current) control.current.remove();

      const routingPlan = new Routing.Plan(
        [L.latLng(from), L.latLng(to)],
        { createMarker: () => false },
      );

      // TODO: Setup OSRM backend (Docker)
      const routingEndpoint = process.env.REACT_APP_ROUTING_URL || 'https://router.project-osrm.org/route/v1';
      const osrm = new Routing.OSRMv1(
        {
          serviceUrl: routingEndpoint,
        },
      );

      control.current = Routing.control({
        router: osrm,
        plan: routingPlan,
        waypoints: [L.latLng(from), L.latLng(to)],
        routeWhileDragging: false,
        show: false,
        // @ts-expect-error Ignore unused parameters
        lineOptions: {
          styles: lineStyles,
          addWaypoints: false,
        },
      })
        .on('routesfound', (routesFound) => estimateDistance(routesFound))
        .addTo(map.current);
    }
  };
  useEffect(() => {
    const loadTripNodes = async () => {
      if (startPointCoordinates && destinationCoordinates) {
        try {
          const tripData = await fetchTripRoutes(startPointCoordinates, destinationCoordinates);
          setRoutes(tripData.routes);
        } catch (error) {
          console.error(error);
        }
      }
    };
    findTrip();
    loadTripNodes();
  }, [startPointCoordinates, destinationCoordinates]);

  return routes;
};
