import { useLocation } from 'react-router-dom';

export const useQueryParams = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const radiusFrom = Number(params.get('radiusFrom'));
  const radiusTo = Number(params.get('radiusTo'));

  return {
    startPoint: JSON.parse(decodeURI(params.get('from')!)),
    destination: JSON.parse(decodeURI(params.get('to')!)),
    startDate: JSON.parse(decodeURI(params.get('date')!)),
    radiusFrom: isNaN(radiusFrom) ? 1000 : radiusFrom,
    radiusTo: isNaN(radiusTo) ? 1000 : radiusTo,
  };
};
