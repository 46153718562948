import React from 'react';
import './modal.scss';

interface CancellationModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

export const CancellationModal: React.FC<CancellationModalProps> =
  ({
     isOpen,
     onClose,
     onConfirm,
   }) => {
    if (!isOpen) return null;
    return (
      <div className="modal-overlay">
        <div className="modal">
          <h2 className="modal-title">Bekreft kansellering</h2>
          <p>Er du sikker på at du vil kansellere denne reisen?</p>
          <div className="modal-actions">
            <button onClick={onClose} className="fremtur-btn small cancel-button">
              Avbryt
            </button>
            <button onClick={onConfirm} className="fremtur-btn small confirm-button">
              Ja, kanseller reisen
            </button>
          </div>
        </div>
      </div>
    );
  };

