import React, { useEffect, useState } from 'react';
import { useFormContext, UseFormTrigger } from 'react-hook-form';
import { useClickOutside } from '../../hooks/useClickOutside';
import { Geocode, GeocodeFeature } from '../../models/Geocode';
import { GeoLocation } from '../../models/GeoLocation';
import { categoriesOfInterest, fetchSuggestions } from '../../services/TripPlannerService';
import { TripFormState } from '../../services/TripService';
import './suggestion-input.scss';

interface InputWithSuggestionsProps {
  name: 'startPoint' | 'destination';
  labelName?: string;
  label: string;
  hasSelected: boolean;
  setHasSelected: (selected: boolean) => void;
  setCoordinates?: (coordinates: number[]) => void;
  trigger?: UseFormTrigger<TripFormState>;
}

export const SuggestionInput: React.FC<InputWithSuggestionsProps> = (
  {
    name,
    labelName,
    label,
    hasSelected,
    setHasSelected,
    setCoordinates,
    trigger,
  },
) => {
  const { register, setValue, watch, formState: { errors } } = useFormContext();
  const [suggestions, setSuggestions] = useState<GeoLocation[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [displayValue, setDisplayValue] = useState('');
  const { inputRef, listRef } = useClickOutside(setShowSuggestions, hasSelected);
  const inputValue = watch(name);

  const filterByCategory = (geocodeFeature: GeocodeFeature) =>
    geocodeFeature.properties.category.some(cat => categoriesOfInterest.includes(cat));
  const mapToGeoLocation = (geocodeFeature: GeocodeFeature) =>
    new GeoLocation(geocodeFeature);

  const loadSuggestions = async (query: string) => {
    try {
      const data: Geocode = await fetchSuggestions(query);
      const geoLocations = data.features
        .filter(filterByCategory)
        .map(mapToGeoLocation);

      setSuggestions(geoLocations);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (inputValue) {
      loadSuggestions(inputValue);
      setHasSelected(false);
      if (!hasSelected) setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  }, [inputValue]);

  const handleSelectSuggestion = (suggestion: GeoLocation) => {
    setValue(name, suggestion);
    setDisplayValue(suggestion.name);
    setShowSuggestions(false);
    setHasSelected(true);
    if (trigger) trigger([name]);
    if (setCoordinates) setCoordinates([suggestion.latitude, suggestion.longitude]);
  };

  return (
    <div className="suggestions">
      <div className="form-group">
        <label htmlFor={name}>{labelName}</label>
        <input
          id={name}
          className="form-input"
          placeholder={label}
          autoComplete="off"
          {...register(name)}
          value={displayValue}
          onChange={(e) => {
            setValue(name, e.target.value);
            setDisplayValue(e.target.value);
            setShowSuggestions(true);
          }}
          onFocus={() => inputValue && setShowSuggestions(true)}
          ref={(e) => {
            register(name).ref(e);
            inputRef.current = e;
          }}
        />
      </div>
      {showSuggestions && suggestions.length > 0 && (
        <ul ref={listRef} className="suggestions-list">
          {suggestions.map((suggestion, id) => (
            <li className="suggestions-list-item" key={id}
                onClick={() => handleSelectSuggestion(suggestion)}>
              <span className="suggestions-place">{suggestion.name}</span>
              <span className="suggestions-county">{suggestion.county}</span>
            </li>
          ))}
        </ul>
      )}
      {errors[name]?.message && < p className="form-error">{errors[name]?.message?.toString()}</p>}
    </div>
  );
};

