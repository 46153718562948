import React from 'react';
import { Trip } from '../../models/Trip';
import { formatDateToWeekday, formatTime } from '../../utilities/DateUtils';
import { formatDistance } from '../../utilities/NumberUtils';
import { Rating } from '../Rating/Rating';

interface TripInfoProps {
  trip: Trip;
  distanceFromDestination?: number;
  distanceFromStartPoint?: number;
  showPrice?: boolean;
  showRating?: boolean;
  showName?: boolean;
}

export const TripInfo: React.FC<TripInfoProps> = (
  {
    trip,
    distanceFromDestination,
    distanceFromStartPoint,
    showPrice,
    showRating,
    showName,
  }) => {
  return (
  <>
    <div className="trip-row">
      <div className="trip-date">{formatDateToWeekday(trip.startDate)}</div>
      <div className="trip-cost">
        {showPrice && (trip.availableSeats === 0
          ? 'Fullt'
          : `Kr. ${trip.reservationPrice},-`)}
      </div>
    </div>
    <div className="trip-row">
      <div className="trip-col">
        <div className="trip-time">{formatTime(trip.startTime)}</div>
      </div>
      <div className="trip-col">
        <div className="trip-timeline">
          <div className="circle"></div>
          <div className="line"></div>
          <div className="circle"></div>
        </div>
      </div>
      <div className="trip-col">
        <div className="trip-segment">
          <div className="trip-location">
            <div className="trip-location-name">{trip.startPoint.name}</div>
            <div className="trip-location-county">{trip.startPoint.county}</div>
            <div className="trip-distance">
              {distanceFromStartPoint && distanceFromStartPoint > 0
                ? <>
                  <i className="trip-distance-icon fa-solid fa-person-walking"></i>
                  {formatDistance(distanceFromStartPoint)} fra ditt startpunkt
                </>
                : <>&nbsp;</>
              }
            </div>
          </div>
        </div>
        <div className="trip-segment">
          <div className="trip-location">
            <div className="trip-location-name">{trip.destination.name}</div>
            <div className="trip-location-county">{trip.destination.county}</div>
            <div className="trip-distance">
              {distanceFromDestination && distanceFromDestination > 0
                ? <>
                  <i className="trip-distance-icon fa-solid fa-person-walking"></i>
                  {formatDistance(distanceFromDestination)} fra din destinasjon
                </>
                : ''
              }
            </div>
          </div>
        </div>
      </div>
      <div className="trip-col available-seats">
          {renderAvailableSeats(trip.availableSeats)}
      </div>
    </div>
    {showName && <Rating trip={trip} showRating={showRating} />}
  </>
  )
}

const renderAvailableSeats = (availableSeats: number): string | null => {
  if (availableSeats === 0) return null
  return availableSeats === 1 ? "1 ledig sete" : `${availableSeats} ledige seter`
}