export enum Gender {
  MALE = 'MALE',
  FEMALE = 'FEMALE',
  UNSPECIFIED = 'UNSPECIFIED'
}

export const genderOptions = {
  [Gender.MALE]: 'Mann',
  [Gender.FEMALE]: 'Kvinne',
  [Gender.UNSPECIFIED]: 'Ikke spesifisert',
};

export const genderOptionsFA = {
  [Gender.MALE]: 'fa-mars',
  [Gender.FEMALE]: 'fa-venus',
  [Gender.UNSPECIFIED]: '',
};
