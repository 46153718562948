import { ChatMessage } from '../models/ChatMessage';
import { Reservation } from '../models/Reservation';
import { User } from '../models/User';

interface ChatRequest {
  name: string,
  reservationId: string
}

export interface Chat {
  id: string,
  name: string,
  reservationId: string,
  reservation?: Reservation
}

export const createChat = async (reservationId: string, token?: string): Promise<Chat> => {
  if (!token) throw new Error('No access token found');

  const chatRequest: ChatRequest = {
    name: reservationId,
    reservationId: reservationId,
  };

  const response = await fetch('/v1/chats', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(chatRequest),
  });

  if (!response.ok)
    throw new Error(
      `Failed to create chat: ${response.status} ${response.statusText}`,
    );
  return response.json();
};

export const fetchChatsOnUser = async (token?: string, user?: User) => {
  if (!token) throw new Error('No access token found');
  if (!user) throw new Error('No user found');

  const response = await fetch('/v1/chats', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok)
    throw new Error(
      `Failed to fetch chats on user: ${response.status} ${response.statusText}`,
    );
  return response.json();
};

export const fetchTicket = async (token?: string): Promise<string> => {
  if (!token) throw new Error('No access token found');

  const response = await fetch('/v1/chats/ticket', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok)
    throw new Error(
      `Failed to fetch ticket: ${response.status} ${response.statusText}`,
    );
  return response.text();
};


export const fetchMessagesOnChat = async (page:number, chat?: Chat, token?: string): Promise<ChatMessage[]> => {
  if (!chat) throw new Error('No chatfound');
  if (!token) throw new Error('No access token found');

  const response = await fetch(`/v1/chats/${chat.id}/messages?page=${page}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok)
    throw new Error(
      `Failed to fetch messages on chat: ${response.status} ${response.statusText}`,
    );
  return response.json();
};
