import React from 'react';
import {MoonLoader} from 'react-spinners';
import "./LoadingComponent.scss"

export const LoadingComponent = ({text}: {text: string}) => {
    return (
        <div className="loading-component">
            <div><MoonLoader/></div>
            <h3>{text}</h3>
        </div>
    )
}