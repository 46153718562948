import React from 'react';
import { useForm } from 'react-hook-form';
import './chat-message-form.scss';

export interface ChatMessageFormValues {
  message: string;
}

interface ChatMessageFormProps {
  sendMessage: (messageData: ChatMessageFormValues) => void;
}

export const ChatMessageForm: React.FC<ChatMessageFormProps> = ({ sendMessage }) => {
  const { register, handleSubmit, reset } = useForm<ChatMessageFormValues>();

  const onSubmit = (data: ChatMessageFormValues) => {
    sendMessage(data);
    reset();
  };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            handleSubmit(onSubmit)();
        }
    };

  return (
    <form className="chat-message-form" onSubmit={handleSubmit(onSubmit)}>
      <textarea
        {...register('message', { required: true })}
        placeholder="Skriv en melding..."
        className="chat-message-input"
        autoComplete="off"
        onKeyDown={handleKeyPress}
      />
      <button type="submit" className="send-button">
        <i className="send-icon fa fa-paper-plane" aria-hidden="true"></i>
      </button>
    </form>
  );
};
