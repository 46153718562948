import { LocationCategory } from '../enums/LocationCategory';
import { ReservationStatus } from '../enums/ReservationStatus';
import { GeoLocation } from '../models/GeoLocation';
import { LocationDTO } from '../models/Location';
import { PriceBasisRequest } from '../models/PriceBasisRequest';
import { Reservation } from '../models/Reservation';
import { ReservationRequest } from '../models/ReservationRequest';
import { ReservationUpdateRequest } from '../models/ReservationUpdateRequest';
import { Trip } from '../models/Trip';
import { TripRequest } from '../models/TripRequest';
import { TripSearch } from '../models/TripSearch';
import { TripSearchResponse } from '../models/TripSearchResponse';
import { User } from '../models/User';
import { createDateAndTimeForJava } from '../utilities/DateUtils';

export interface TripFormState {
  startDate: string;
  startTime: string;
  startPoint: GeoLocation;
  destination: GeoLocation;
  vehicleNumber: string;
  maxCapacity: number;
  distance: number;
  tolls: number;
  ferryCost: number;
}

export const getLocationCategory = (category: string[]) => {
  // TODO: Only handle first category?
  const mainCategory = category[0];
  switch (mainCategory) {
    case 'tettsted':
    case 'tettbebyggelse':
    case 'GroupOfStopPlaces':
      return LocationCategory.OTHER;
    case 'onstreetBus':
      return LocationCategory.ADDRESS;
    case 'airport':
      return LocationCategory.AIRPORT;
    case 'busStation':
      return LocationCategory.BUS_STATION;
    case'railStation':
      return LocationCategory.RAIL_STATION;
    default:
      return LocationCategory.OTHER;
  }
};

const createLocationDTO = (geoLocation: GeoLocation): LocationDTO => {
  return {
    name: geoLocation.name,
    county: geoLocation.county,
    longitude: geoLocation.longitude,
    latitude: geoLocation.latitude,
    locationCategory: getLocationCategory(geoLocation.category),
  };
};
;
export const createTrip = async (
  data: TripFormState,
  token?: string,
  user?: User,
): Promise<Trip> => {
  if (!token) throw new Error('No access token found');
  if (!user) throw new Error('No user found');

  const priceBasis: PriceBasisRequest = {
    distance: data.distance,
    tolls: data.tolls,
    ferryCost: data.ferryCost,
  };
  const formattedDateTime = createDateAndTimeForJava(data.startDate, data.startTime);


  const from: LocationDTO = createLocationDTO(data.startPoint);
  const to: LocationDTO = createLocationDTO(data.destination);
  const tripRequest: TripRequest = {
    startDate: formattedDateTime.isoDate,
    startTime: formattedDateTime.isoTime,
    startPoint: from,
    destination: to,
    maxCapacity: data.maxCapacity,
    vehicleId: data.vehicleNumber,
    driverId: user.id,
    priceBasis: priceBasis,
  };

  const response = await fetch(`/v1/trips`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(tripRequest),
  });

  if (!response.ok)
    throw new Error(
      `Failed to create trip: ${response.status} ${response.statusText}`,
    );
  return response.json();
};

export const fetchTrip = async (
  token?: string,
  tripId?: string,
): Promise<Trip> => {
  if (!token) throw new Error('No access token found');
  if (!tripId) throw new Error('Not a valid trip id');

  const response = await fetch(`/v1/trips/${tripId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok)
    throw new Error(
      `Failed to fetch trip: ${response.status} ${response.statusText}`,
    );
  return response.json();
};


export const searchTrips = async (
  searchPredicates: TripSearch[],
): Promise<TripSearchResponse[]> => {
  const tripSearchRequest = { searchPredicates };

  interface PaginationParams {
    page: number;
    size: number;
  }

  const paginationParams: PaginationParams = {
    page: 0,
    size: 25,
  };
  const { page, size } = paginationParams;
  const endpoint = `/v1/trips/search?page=${page}&size=${size}`;

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(tripSearchRequest),
  });

  if (!response.ok)
    throw new Error(
      `Failed to search for trip: ${response.status} ${response.statusText}`,
    );
  return response.json();
};

export const createReservation = async (
  tripId: string,
  passengers: number,
  token?: string,
  user?: User,
) => {
  if (!token) throw new Error('No access token found');
  if (!user) throw new Error('No user found');


  const endpoint = `/v1/trips/${tripId}/reservations`;
  const reservationRequest: ReservationRequest = {
    userId: user.id,
    passengers
  };

  const response = await fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(reservationRequest),
  });

  if (!response.ok) {
    throw new Error(
      `Failed to create reservation: ${response.status} ${response.statusText}`,
    );
  }
};

export const updateReservationStatus = async (status: ReservationStatus, reservationId: string, tripId: string, token?: string) => {
  if (!token) throw new Error('No access token found');

  const updateRequest: ReservationUpdateRequest = {
    newStatus: status,
  };
  const response = await fetch(`/v1/trips/${tripId}/reservations/${reservationId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(updateRequest),
  });

  if (!response.ok) {
    throw new Error(
      `Failed to update reservation: ${response.status} ${response.statusText}`,
    );
  }
};

export const fetchReservationsOnTrip = async (
  tripId: string,
  token?: string,
): Promise<Reservation[]> => {
  if (!token) throw new Error('No access token found');

  const response = await fetch(`/v1/trips/${tripId}/reservations`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok)
    throw new Error(
      `Failed to fetch reservations: ${response.status} ${response.statusText}`,
    );

  return response.json();
};
