import React from "react"
import {useQuery} from 'react-query';
import {Link, useParams} from 'react-router-dom';
import {Gender, genderOptionsFA} from '../../enums/Gender';
import {fetchUserPublicProfile} from '../../services/UserService';
import {formatDateToReadableDateWithYear} from '../../utilities/DateUtils';
import {useAuth} from '../AuthProvider/AuthProvider';
import {LoadingComponent} from '../common/LoadingComponent';
import "./PublicProfilePage.scss"

export const PublicProfilePage = () => {
    const {token} = useAuth();
    const {userId} = useParams<'userId'>();
    const {data, isError, isLoading} = useQuery(
        ["public-profile", userId],
        () => fetchUserPublicProfile(userId, token),
        {enabled: !!userId}
    )

    return (
        <div className="container-fremtur">
            <div className="container-center">
                {isLoading && <LoadingComponent text={"Laster inn bruker"}/>}
                {isError && <h3>Klarte ikke hente bruker</h3>}
                {data &&
                    <div className="public-profile-info">
                        <div className="public-profile-row">
                            <ProfilePicture gender={data.gender}/>
                            <div>
                                <h2>
                                    {data.name} <i className={`fa ${genderOptionsFA[data.gender]}`}/>
                                </h2>
                                <p>{data.age} år</p>
                                <p>Medlem siden {formatDateToReadableDateWithYear(data.registrationDate)}</p>
                            </div>
                        </div>
                        <div className="divider"/>
                        <Link to="passasjer-vurdering" className="fremtur-link">
                            <div className="public-profile-row space-between hover">
                                <p>Vurdering som passasjer: {data.rating}/5 ★</p>
                                <i className="fa-solid fa-arrow-right"/>
                            </div>
                        </Link>
                    </div>
                }
            </div>
        </div>
    )
}

const ProfilePicture = ({gender}: { gender: Gender }) => {
    return (
        <div className="profile-picture">
            {/*TODO: Replace placeholder with real profile picture*/}
            {
                gender === Gender.MALE ?
                    <img src="/profile-placeholder-male.png" alt="profile picture"/>
                    : gender === Gender.FEMALE ?
                        <img src="/profile-placeholder-female.png" alt="profile picture"/>
                        :
                        <img src="/profile-placeholder-unspecified.png" alt="profile picture"/>
            }
        </div>
    )
}