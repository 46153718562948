import React from 'react';
import {useQuery} from 'react-query';
import {useParams} from 'react-router-dom';
import {RatingType} from '../../enums/RatingType';
import {fetchPublicRatings, RatingResponse} from '../../services/RatingService';
import {formatDateToReadableDateWithYear} from '../../utilities/DateUtils';
import {useAuth} from '../AuthProvider/AuthProvider';
import "./PassengerRatingPage.scss"

export const PassengerRatingPage = () => {
    const {token} = useAuth();
    const {userId} = useParams<'userId'>();
    const {data} = useQuery(["passenger-rating", userId], () => {
        return fetchPublicRatings(RatingType.PASSENGER_RATING, userId, token);
    })

    return (
        <div className="container-fremtur">
            <div className="container-center">
                <div className="passenger-rating">
                    <h2>Vurderinger som passasjer</h2>
                    {data && <RatingDistributionComponent ratings={data}/>}
                    <div className="divider"/>
                    <div className="ratings">
                        {data && data.map((rating, index) => <RatingComponent key={index} rating={rating}/>)}
                    </div>
                </div>
            </div>
        </div>
    )
}

const RatingComponent = ({rating}: { rating: RatingResponse }) => {
    return (
        <div className="rating">
            <p>{rating.rating}/5 ★</p>
            <p>{rating.comment}</p>
            <p className="gray">{formatDateToReadableDateWithYear(rating.receivedAt)}</p>
        </div>
    )
}

const RatingDistributionComponent = ({ratings}: { ratings: RatingResponse[] }) => {
    const distributionList = []
    for (let i = 1; i <= 5; i++) {
        const count = ratings.filter(rating => rating.rating == i).length
        distributionList.push({rating: i, count: count})
    }

    return (
        <div className="rating-distribution">
            {
                distributionList.map(({count, rating}) => (
                    <div key={rating} className="rating-distribution-item">
                        <span>{"★".repeat(rating).padEnd(5, "⚝")}</span>
                        <span>{count}</span>
                    </div>
                ))
            }
        </div>
    )
}