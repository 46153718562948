import React, { useEffect, useRef, useState } from 'react';
import * as L from 'leaflet';

const INIT_COORDINATES: [number, number] = [60.472, 8.4689];
const INIT_ZOOM = 4;

export const useMap = (mapContainer: React.RefObject<HTMLDivElement>) => {
  const map = useRef<L.Map | null>(null);
  const tileLayer = useRef<L.TileLayer | null>(null);
  const [isDarkMode, setIsDarkMode] = useState<boolean>(false);

  useEffect(() => {
    if (!map.current && mapContainer.current) {
      map.current = new L.Map(
        mapContainer.current,
        {
          center: INIT_COORDINATES,
          zoom: INIT_ZOOM,
          zoomControl: false,
          boxZoom: false,
        });

      tileLayer.current = L.tileLayer('https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png', {
        minZoom: 0,
        maxZoom: 20,
      }).addTo(map.current);
    }
  }, []);

  const toggleDarkMode = (isDark: boolean) => {
    if (map.current) {
      if (tileLayer.current) {
        map.current.removeLayer(tileLayer.current);
      }
      tileLayer.current = L.tileLayer(`https://tiles.stadiamaps.com/tiles/alidade_smooth${isDarkMode ? '' : '_dark'}/{z}/{x}/{y}{r}.png`, {}).addTo(map.current);
      setIsDarkMode(!isDark);
    }
  };

  return { map, tileLayer, isDarkMode, toggleDarkMode };
};
