import { Geocode } from '../models/Geocode';

// TODO: Adjust categories?
export const categoriesOfInterest = ['tettbebyggelse', 'tettsted', 'onstreetBus', 'airport', 'busStation', 'railStation', 'GroupOfStopPlaces'];

export const fetchSuggestions = async (query: string): Promise<Geocode> => {
  const response = await fetch(`https://api.entur.io/geocoder/v1/autocomplete?text=${query}&size=10&lang=no`, {
    headers: {
      'Content-Type': 'application/json',
      'ET-Client-Name': 'fremtur-tripplanner',
    },
    method: 'GET',
  });

  if (!response.ok)
    throw new Error(
      `Failed to create trip: ${response.status} ${response.statusText}`,
    );
  return response.json();
};

export const fetchTripRoutes = async (from: number[], to: number[]) => {
  //  TODO: Setup OSRM backend (Docker)
  const routingEndpoint = process.env.REACT_APP_ROUTING_URL || 'https://router.project-osrm.org';
  const [lonFrom, latFrom] = from as [number, number];
  const [lonTo, latTo] = to as [number, number];
  const response = await fetch(`${routingEndpoint}/route/v1/driving/${latFrom},${lonFrom};${latTo},${lonTo}?overview=false&alternatives=true&steps=true&annotations=true`);

  if (!response.ok)
    throw new Error(
      `Failed to create trip: ${response.status} ${response.statusText}`,
    );
  return response.json();
};

export const fetchTollData = async () => {
  const overpassQuery = `
      [out:json];
      area["name"="Norge"]->.searchArea;
      (
        node(area.searchArea)["ref:toll"];
      );
      out body;
      `;
  const overpassUrl = `https://overpass-api.de/api/interpreter?data=${encodeURI(overpassQuery.trim())}`;
  const response = await fetch(overpassUrl);

  if (!response.ok)
    throw new Error(
      `Failed to create trip: ${response.status} ${response.statusText}`,
    );
  return response.json();
};

export const fetchFerryData = async () => {
  const overpassQuery = `
      [out:json];
      area["name"="Norge"]->.searchArea;
      (
        way(area.searchArea)["route"="ferry"];
      );
      out geom;
      `;
  const overpassUrl = `https://overpass-api.de/api/interpreter?data=${encodeURI(overpassQuery.trim())}`;
  const response = await fetch(overpassUrl);
  if (!response.ok)
    throw new Error(
      `Failed to create trip: ${response.status} ${response.statusText}`,
    );
  return response.json();
};
