import * as Yup from 'yup';
import { GeoLocation } from '../models/GeoLocation';


export const geoLocationSchema = Yup.object<GeoLocation>().shape({
  name: Yup.string().required('Navn er påkrevd'),
  county: Yup.string().required('Fylke er påkrevd'),
  longitude: Yup
    .number()
    .required('Lengdegrad er påkrevd'),
  latitude: Yup
    .number()
    .required('Breddegrad er påkrevd'),
  category: Yup
    .array()
    .of(Yup.string().required('Kategori må bestå av tekst'))
    .required('Kategori er påkrevd')
    .min(1, 'Det må være minst én kategori'),
});