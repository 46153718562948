import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useFetchTrip } from '../../hooks/useFetchTrip';
import { useAuth } from '../AuthProvider/AuthProvider';
import { TripInfoCard } from '../TripInfoCard/TripInfoCard';
import './trip-info-page.scss';

export const TripInfoPage = () => {
  const { token } = useAuth();
  const navigate = useNavigate();
  const { tripId } = useParams<'tripId'>();
  const { trip, tripError } = useFetchTrip(tripId, token);

  const handleClick = () => {
    if (trip) {
      navigate('reserver');
    } else {
      console.error('No trip available');
    }
  };

  return (
    <div className="container-fremtur">
      <div className="container-center trip-container ">
        <h1 className="fremtur-heading">Reiseinformasjon</h1>
      </div>
      <div className="container-center trip-container ">
        {tripError && <div className="error-message">{tripError}</div>}
        {trip && (
          <>
            <TripInfoCard trip={trip} />
            <button className="fremtur-btn" onClick={handleClick}>
              Reserver reisen
            </button>
          </>
        )}
      </div>
    </div>
  );
};
