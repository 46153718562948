import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';
import { User } from '../../models/User';
import { fetchUser } from '../../services/UserService';

interface AuthContextType {
  isLoggedIn: boolean;
  user?: User;
  token?: string;
  login: (token: string, user: User) => void;
  updateUserContext: (user: User) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: ReactNode }) => {
  const [isLoggedIn, setLoggedIn] = useState<boolean>(false);
  const [token, setToken] = useState<string | undefined>(() => Cookies.get('access_token'));
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const loadUser = async () => {
      if (token) {
        const user = await fetchUser(token);
        login(token, user);
      }
    };
    loadUser();
  }, []);

  const login = (token: string, user: User) => {
    // TODO Update expiry time in prod
    const inTenMinutes = new Date(Date.now() + 599 * 1000);
    Cookies.set('access_token', token, { expires: inTenMinutes });
    setToken(token);
    setUser(user);
    setLoggedIn(true);
  };

  const updateUserContext = (updatedUser: User) => {
    setUser(updatedUser);
  };

  return (
    <AuthContext.Provider
      value={{ isLoggedIn, user, token, login, updateUserContext }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
