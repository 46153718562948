import React from 'react';
import { ReservationStatus } from '../../enums/ReservationStatus';
import { Reservation } from '../../models/Reservation';
import { Chat } from '../../services/ChatService';
import { formatDateToReadableDate } from '../../utilities/DateUtils';
import './chat-list.scss';
import { useAuth } from '../AuthProvider/AuthProvider';

interface ChatListProps {
  reservations: Reservation[];
  chats: Chat[];
  currentChat: Chat | undefined;
  handleReservationClick: (reservationId: string) => void;
  handleChatClick: (chat: Chat) => void;
  error?: string;
}

export const ChatList: React.FC<ChatListProps> =
  ({
     reservations,
     chats,
     currentChat,
     handleReservationClick,
     handleChatClick,
     error,
   }) => {
    const { user } = useAuth();
    const reservationStatus = (reservation: Reservation) =>
      (reservation.status == ReservationStatus.APPROVED)
      && <>
        <i className="fa-solid fa-circle fa-2xs"></i>
        {} Bekreftet &bull;
      </>;

    return (
      <div className="chat">
        {error && <div className="error-message">{error}</div>}
        <div className="chat-header">
          Meldinger
        </div>
        <ul className="chat-list">
          {reservations.length === 0 && chats.length === 0 &&
            <div className="chat-empty">
              Du har ingen samtaler
            </div>
          }
          {reservations.map((reservation, id) => (
            <li className="chat-list-row" key={id}>
              <button onClick={() => handleReservationClick(reservation.id)} className="chat-bubble">
                <div className="chat-icon">
                  {/*TODO: Replace icon with profile picture*/}
                  <i className="header-icon fa fa-user-circle-o" aria-hidden="true"></i>
                </div>
                <div className="chat-info">
            <span className="chat-name">
              {reservation.trip.driver.name == user?.name
                ? reservation.user.name
                : reservation.trip.driver.name}
            </span>
                  <span className="chat-reservation-details">
              {reservationStatus(reservation)} {reservation.trip.startPoint.name} - {reservation.trip.destination.name} &bull; {formatDateToReadableDate(reservation.trip.startDate)}
            </span>
                </div>
              </button>
            </li>
          ))}
          {chats.map((chat, id) => (
            <li className="chat-list-row" key={id}>
              <button
                onClick={() => handleChatClick(chat)}
                className={`chat-bubble ${currentChat && currentChat.reservationId === chat.reservationId ? 'active' : ''}`}
              >
                <div className="chat-icon">
                  {/*TODO: Replace icon with profile picture*/}
                  <i className="header-icon fa fa-user-circle-o" aria-hidden="true"></i>
                </div>
                <div className="chat-info">
            <span className="chat-name">
              {chat.reservation?.trip.driver.name == user?.name
                ? chat.reservation?.user.name
                : chat.reservation?.trip.driver.name}
            </span>
                  {chat.reservation && (
                    <span className="chat-reservation-details">
                {reservationStatus(chat.reservation)} {chat.reservation.trip.startPoint.name} - {chat.reservation.trip.destination.name} &bull; {formatDateToReadableDate(chat.reservation.trip.startDate)}
              </span>
                  )}
                </div>
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };
