import { useEffect, useState } from 'react';
import { ChatMessage } from '../models/ChatMessage';
import { Chat, fetchMessagesOnChat } from '../services/ChatService';

export const useMessages = (page: number, token?: string, chat?: Chat) => {
  const [messages, setMessages] = useState<ChatMessage[]>([]);
  const [error, setError] = useState<string>();

  useEffect(() => {
    const loadMessages = async (page: number) => {
      if (chat) {
        try {
          const messageData = await fetchMessagesOnChat(page, chat, token);
          const messageDataAsc = messageData.reverse();
          page === 0
            ? setMessages(messageDataAsc)
            : setMessages(prevMessages => [...messageDataAsc, ...prevMessages]);
          setError('');
        } catch (error) {
          setError('Klarte ikke å hente meldinger');
          console.error(error);
        }
      }
    };
    loadMessages(page);
  }, [chat, page]);

  return {
    messages:messages,
    setMessages: setMessages,
    messageError: error
  };
};

