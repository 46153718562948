import React from 'react';

interface Props{
    passengers: number,
    reservationPrice: number
}

export const ReservationPriceDetails = ({passengers, reservationPrice}: Props) => {
    return (
        <>
            <div className="trip-details-row">
                <div className="trip-text">Pris per passasjer:</div>
                <div className="trip-bold">Kr. {reservationPrice.toFixed(2)},-</div>
            </div>
            <div className="trip-details-row">
                <div className="trip-text">Totalpris:</div>
                <div className="trip-bold">Kr. {(reservationPrice * passengers).toFixed(2)},-</div>
            </div>
        </>
    )
}