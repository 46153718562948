export enum ReservationStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELED = 'CANCELED'
}


export const reservationStatusText= {
  [ReservationStatus.PENDING]: 'Til godkjenning',
  [ReservationStatus.APPROVED]: 'Godkjent',
  [ReservationStatus.REJECTED]: 'Avslått',
  [ReservationStatus.CANCELED]: 'Kansellert',
};

