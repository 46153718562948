import React from 'react';
import './footer.scss';

export const Footer = () => (
  <footer>
    <p className="footer-info">
      © 2024 Fremtur. En tjeneste fra Kodio AS, Oslo, Storgata 17. Org.nr. 923
      674 705, <a href="mailto:hei@kodio.no">hei@kodio.no</a>,{' '}
      <a href="tel:+4797654405">+47 97 65 44 05</a>.
    </p>
    <div className="social-links">
      <a href="https://www.instagram.com/Fremtur" className="social-link" aria-label="Fremtur på Instagram">
        <i className="fab fa-instagram"></i>
      </a>
      <a href="https://www.twitter.com/Fremtur" className="social-link" aria-label="Fremtur på Twitter/X">
        <i className="fab fa-twitter"></i>
      </a>
    </div>
  </footer>
);
