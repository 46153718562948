import * as Yup from 'yup';
import { SearchFormState } from '../components/SearchPage/SearchPage';
import { emptyStringOrGeoLocationSchema } from './EmptyStringOrGeoLocationSchema';

export const searchFormSchema = Yup.object<SearchFormState>().shape({
  startPoint: emptyStringOrGeoLocationSchema,
  destination: emptyStringOrGeoLocationSchema,
  startDate: Yup.string()
    .test('minDate', 'Dato må være i dag eller senere', (value) => {
      if (!value) return true;

      const inputDate = new Date(value);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return inputDate >= today;
    })
    .nullable(),
  radiusFrom: Yup.number().required(),
  radiusTo: Yup.number().required()
});
