import { format } from 'date-fns';
import { nb } from 'date-fns/locale';
import { capitalize } from './StringUtils';


export const createDateAndTimeForJava = (date: string, time: string) => {
  const parsedStartDate = new Date(date);
  const parsedStartTime = new Date(time);

  const isoDate = formatDateToYYYYMMDD(parsedStartDate);
  const isoTime = formatDateToHHMMSS(parsedStartTime);

  return { isoDate, isoTime };
};
export const formatDate = (date: Date) => format(date, 'dd/MM/yyyy');

export const formatDateToYYYYMMDD = (date: Date) => format(date, 'yyyy-MM-dd');
export const formatDateToDDMMMYYYY = (date: Date) => format(date, 'dd/MM/yyyy');
export const formatDateToHHMMSS = (date: Date) => format(date, 'HH:mm:ss');

export const formatDateToWeekday = (date: Date) =>
  capitalize(format(date, 'eeee, d. MMMM', { locale: nb }));

export const formatDateToReadableDateWithYear = (date: Date) =>
  capitalize(format(date, 'd. MMMM yyyy', { locale: nb }));

export const formatDateToReadableDate = (date: Date) =>
  format(date, 'd. MMMM', { locale: nb });

export const formatTime = (dateInSeconds: string) =>
  dateInSeconds.substring(0, 5);


export const isInPast = (date: Date) => new Date(date) < new Date();
