import React, {useState} from 'react';
import {Link, useParams} from 'react-router-dom';
import {useFetchTrip} from '../../hooks/useFetchTrip';
import {Reservation} from '../../models/Reservation';
import {createReservation} from '../../services/TripService';
import {fetchReservationsOnUser} from '../../services/UserService';
import {formatDate} from '../../utilities/DateUtils';
import {useAuth} from '../AuthProvider/AuthProvider';
import {TripInfo} from '../TripInfo/TripInfo';
import {PassengersCount} from './PassengersCount';

export const ReservationConfirmationPage = () => {
  const { tripId } = useParams<'tripId'>();
  const { token, user } = useAuth();
  const [reservation, setReservation] = useState<Reservation>();
  const { trip, tripError } = useFetchTrip(tripId, token);
  const [error, setError] = useState<string>();
  const [passengersCount, setPassengersCount] = useState(1)

  const handleClick = async () => {
    if (tripId) {
      try {
        await createReservation(tripId, passengersCount, token, user);
        const reservationData = await fetchReservationsOnUser(token, user);
        const currentReservation = reservationData.find(
          (reservation) => reservation.trip.id == tripId,
        );
        setReservation(currentReservation);
      } catch (error) {
        setError('Klarte ikke å reservere turen');
        console.error(error);
      }
    }
  };

  return (
    <div className="container-fremtur">
      <div className="container-center trip-container ">
        <h1 className="fremtur-heading">Gjennomgå din reservasjon</h1>
        <p className="fremtur-paragraph">
          Din reservasjon vil ikke bli bekreftet før sjåføren godkjenner
          forespørselen.
        </p>
      </div>
      <div className="container-center trip-container">
        {error && <div className="error-message">{error}</div>}
        {tripError && <div className="error-message">{tripError}</div>}
        {trip && reservation && (
          <>
            <div className="heading-nb">
              Reise fra {trip.startPoint.name} til {trip.destination.name} den{' '}
              {formatDate(trip.startDate)} ble reservert
            </div>
            <Link to="/reiser">
              <div className="form-row">
                <button className="fremtur-btn">Tilbake til mine reiser</button>
              </div>
            </Link>
          </>
        )}
        {trip && !reservation && (
          <>
            <div className="trip-list">
              <div className="trip-card">
                <TripInfo trip={trip} showName={true} />
                <div className="trip-divider"/>
                <div className="trip-details-row">
                  <div className="trip-text">Kjøretøy:</div>
                  <div className="trip-bold">
                    {trip.licensePlateNumber} {trip.vehicleDetails.color} {trip.vehicleDetails.brand}
                  </div>
                </div>
                <div className="trip-divider"/>
                <PassengersCount availableSeats={trip.availableSeats} passengersCount={passengersCount} setPassengersCount={setPassengersCount}/>
                <div className="trip-divider"/>
                <div className="trip-details-row">
                  <div className="trip-text">Prissammendrag:</div>
                  <div className="trip-bold">Kr. {(trip.reservationPrice*passengersCount).toFixed(2)},-</div>
                </div>
              </div>
            </div>
            <button className="fremtur-btn" onClick={handleClick}>Bekreft reservasjon</button>
          </>
        )}
      </div>
    </div>
  );
};