import React from 'react';
import './modal.scss';

interface ApprovalModalProps {
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
  onReject: () => void;
}

export const ApprovalModal: React.FC<ApprovalModalProps> =
  ({
     isOpen,
     onClose,
     onAccept,
     onReject,
   }) => {
    if (!isOpen) return null;
    return (
      <div className="modal-overlay">
        <div className="modal">
          <h2 className="modal-title">Behandle reiseforespørsel</h2>
          <p>Ønsker du å godkjenne eller avvise denne reiseforespørselen?</p>
          <div className="modal-actions">
            <button onClick={onClose} className="fremtur-btn small cancel-button">
              Avbryt
            </button>
            <button onClick={onReject} className="fremtur-btn small reject-button">
              Avvis reisen
            </button>
            <button onClick={onAccept} className="fremtur-btn small approve-button">
              Godkjenn reisen
            </button>
          </div>
        </div>
      </div>

    );
  };

