import { useEffect, useState } from 'react';
import { fetchFerryData } from '../services/TripPlannerService';

export interface FerryRoute {
  nodes: number[],
  tags: { [key: string]: string }
}

export const useFerryRoutes = () => {
  const [ferryRoutes, setFerryRoutes] = useState<FerryRoute[]>();

  useEffect(() => {
    const loadFerryRoutes = async () => {
      try {
        const ferryData = await fetchFerryData();
        setFerryRoutes(ferryData.elements);
      } catch (error) {
        console.error(error);
      }
    };
    loadFerryRoutes();
  }, []);

  return ferryRoutes;
};
