import * as Yup from 'yup';
import { geoLocationSchema } from './GeoLocationSchema';

export const tripFormSchema = Yup.object().shape({
  startDate: Yup.string()
    .required('Oppgi dato når du reiser')
    .test('minDate', 'Dato må være i dag eller senere', (value) => {
      const inputDate = new Date(value);
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return inputDate >= today;
    }),
  startTime: Yup.string()
    .required('Oppgi klokkeslett når du reiser'),
  startPoint: geoLocationSchema.required('Oppgi hvor du reiser fra').typeError('Velg fra listen med stedforslag'),
  destination: geoLocationSchema.required('Oppgi hvor du reiser til').typeError('Velg fra listen med stedforslag'),
  vehicleNumber: Yup.string()
    .required('Oppgi hvilket kjøretøy du skal bruke'),
  maxCapacity: Yup.number()
    .typeError('Antall ledige plasser må være et tall')
    .required('Oppgi ledige plasser')
    .min(1, 'Antall ledige plasser må være minst 1')
    .max(8, 'Du kan ha maksimalt 8 passasjerer'),
  distance: Yup.number()
    .typeError('Avstand må være et tall')
    .required('Oppgi avstand i kilometer'),
  tolls: Yup.number()
    .typeError('Kostnaden må være et tall')
    .required('Oppgi bompenger i kroner'),
  ferryCost: Yup.number()
    .typeError('Kostnaden må være et tall')
    .required('Oppgi ferjekostnader i kroner'),
});
