import React, { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { nb } from 'date-fns/locale/nb';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { TripSearchOperator } from '../../enums/TripSearchOperator';
import { TripSearchTerm } from '../../enums/TripsSearchTerm';
import { useQueryParams } from '../../hooks/useQueryParams';
import { GeoLocation } from '../../models/GeoLocation';
import { TripSearch } from '../../models/TripSearch';
import { TripSearchResponse } from '../../models/TripSearchResponse';
import { searchFormSchema } from '../../schemas/SearchFormSchema';
import { searchTrips } from '../../services/TripService';
import { formatDateToYYYYMMDD } from '../../utilities/DateUtils';
import { Slider } from '../Slider/Slider';
import { SuggestionInput } from '../SuggestionInput/SuggestionInput';
import { TripList } from '../TripList/TripList';
import 'react-datepicker/dist/react-datepicker.css';
import './search-page.scss';

registerLocale('nb', nb);

export interface SearchFormState {
  startPoint?: GeoLocation | string;
  destination?: GeoLocation | string;
  startDate?: string | null;
  radiusFrom: number;
  radiusTo: number;
}

const createSearchParams = (
  from: GeoLocation | string,
  to: GeoLocation | string,
  date: string,
  radiusFrom: number,
  radiusTo: number,
): TripSearch[] => {
  const searchParams: TripSearch[] = [];

  if (typeof from !== 'string') {
    searchParams.push({
      searchTerm: TripSearchTerm.START_POINT_WITHIN,
      searchOperator: TripSearchOperator.EQUAL,
      value: `${from.latitude}_${from.longitude}_${radiusFrom}`,
    });
  }

  if (typeof to !== 'string') {
    searchParams.push({
      searchTerm: TripSearchTerm.DESTINATION_WITHIN,
      searchOperator: TripSearchOperator.EQUAL,
      value: `${to.latitude}_${to.longitude}_${radiusTo}`,
    });
  }

  if (date) {
    searchParams.push({
      searchTerm: TripSearchTerm.START_DATE,
      searchOperator: TripSearchOperator.EQUAL,
      value: date,
    });
  }

  return searchParams;
};

export const SearchPage = () => {
  const methods = useForm<SearchFormState>({
    resolver: yupResolver(searchFormSchema),
    defaultValues: {
      startPoint: '',
      destination: '',
      startDate: '',
      radiusFrom: 1000,
      radiusTo: 1000,
    },
  });
  const { handleSubmit, setValue } = methods;
  const location = useLocation();
  const navigate = useNavigate();
  const [trips, setTrips] = useState<TripSearchResponse[]>();
  const [error, setError] = useState<string>();
  const [startPointSelected, setStartPointSelected] = useState(false);
  const [destinationSelected, setDestinationSelected] = useState(false);
  const { startPoint, destination, startDate, radiusFrom, radiusTo } = useQueryParams();

  useEffect(() => {
    if (location.pathname === '/search') {
      if (startPoint && startPoint != '') {
        setValue('startPoint', startPoint as GeoLocation);
        setStartPointSelected(true);
      }
      if (destination && destination != '') {
        setValue('destination', destination as GeoLocation);
        setDestinationSelected(true);
      }
      if (startDate && startDate != '') {
        setValue('startDate', startDate);
      }
      setValue('radiusFrom', radiusFrom);
      setValue('radiusTo', radiusTo);

      const from = startPoint && startPoint != '' ? startPoint as GeoLocation : '';
      const to = destination && destination != '' ? destination as GeoLocation : '';
      const date = startDate ? formatDateToYYYYMMDD(new Date(startDate)) : '';
      performSearch(from, to, date, radiusFrom, radiusTo);
    }
  }, [location.search]);

  const onSearch = (state: SearchFormState) => {
    const from = typeof state.startPoint !== 'string' ? state.startPoint! : '';
    const to = typeof state.destination !== 'string' ? state.destination! : '';
    const date = state.startDate ? formatDateToYYYYMMDD(new Date(state.startDate)) : '';
    const fromUri = encodeURI(JSON.stringify(from));
    const toUri = encodeURI(JSON.stringify(to));
    const dateUri = encodeURI(JSON.stringify(date));
    const radiusFromUri = state.radiusFrom?.toString() || '0';
    const radiusToUri = state.radiusTo?.toString() || '0';

    navigate(`/search?from=${fromUri}&to=${toUri}&date=${dateUri}&radiusFrom=${radiusFromUri}&radiusTo=${radiusToUri}`);

    if (location.pathname === '/search') {
      performSearch(from, to, date, state.radiusFrom, state.radiusTo);
    }
  };

  const performSearch = async (from: GeoLocation | string, to: GeoLocation | string, date: string, radiusFrom: number, radiusTo: number) => {
    try {
      const searchParams = createSearchParams(from, to, date, radiusFrom, radiusTo);
      const tripData = await searchTrips(searchParams);
      setTrips(tripData);
      setError('');
    } catch (error) {
      setError('Klarte ikke å søke etter reiser');
      console.error(error);
    }
  };

  return (
    <div className="container-fremtur">
      <div className="container-center">
        <h1 className="fremtur-heading">Søk etter reise</h1>
        <FormProvider {...methods}>
          <form className="search-form" onSubmit={handleSubmit(onSearch)}>
            <div>
              <SuggestionInput
                name="startPoint"
                label="Reiser fra"
                hasSelected={startPointSelected}
                setHasSelected={setStartPointSelected}
              />
              <Slider
                min={200}
                max={20000}
                label="radiusFrom"
              />
            </div>
            <div>
              <SuggestionInput
                name="destination"
                label="Reiser til"
                hasSelected={destinationSelected}
                setHasSelected={setDestinationSelected}
              />
              <Slider
                min={200}
                max={20000}
                label="radiusTo"
              />
            </div>
            <div className="form-group">
              <label htmlFor="date"></label>
              <Controller
                name="startDate"
                control={methods.control}
                render={({ field }) => (
                  <DatePicker
                    minDate={new Date()}
                    locale="nb"
                    placeholderText="Dato"
                    formatWeekDay={(day: string) => day.substr(0, 3)}
                    onChange={(date: Date) => field.onChange(date)}
                    selected={field.value}
                    dateFormat="EEEE, d. MMMM"
                    id="date"
                    autoComplete="off"
                    customInput={
                      <input
                        className="form-input form-input-date"
                        type="text"
                        id="date"
                        placeholder="Dato" />}
                  />
                )}
              />
              {methods.formState.errors.startDate &&
                <p className="form-error">{methods.formState.errors.startDate.message}</p>}
            </div>
            <div className="form-group">
              <label htmlFor="search"></label>
              <button id="search" className="search-btn fremtur-btn" type="submit">
                Søk
              </button>
            </div>
          </form>
        </FormProvider>
      </div>
      {error && <div className="container-center error-message">{error}</div>}
      <div className="container-center">
        {trips && (trips.length === 0
          ? <h2>Ingen reiser tilgjengelig.</h2>
          : <TripList trips={trips} showName={true} />)
        }
      </div>
    </div>
  );
};
