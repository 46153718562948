import { useEffect, useRef } from 'react';

export const useClickOutside = (setShowSuggestions: (showSuggestion: boolean) => void, hasSelected: boolean) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const listRef = useRef<HTMLUListElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      inputRef.current && !inputRef.current.contains(event.target as Node) &&
      listRef.current && !listRef.current.contains(event.target as Node)
    ) {
      setShowSuggestions(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [hasSelected]);

  return {
    inputRef,
    listRef,
  };
};

