import { useEffect } from 'react';
import { FerryRoute } from './useFerryRoutes';
import { Route } from './useRoutes';
import { TollData } from './useTolls';

export const useTripCost = (routes?: Route[], ferryRoutes?: FerryRoute[], setFerryCost?: (cost: number) => void, tolls?: TollData[], setTollCost?: (cost: number) => void) => {
  const estimateTollCost = (routes: Route[], tolls: TollData[], setTollCost: (tollCost: number) => void) => {
    const mainRoute = routes.at(0)!;
    const routeNodes = mainRoute.legs[0].annotation.nodes;
    const foundNodes = tolls.filter(tollNode => routeNodes.find(nodeId => tollNode.id === nodeId));
    let totalTollCost = 0;
    foundNodes.forEach(tollNode => {
      if (tollNode.tags['charge:motorcar']) {
        const tollCostStr = tollNode.tags['charge:motorcar'].split(' ')[0];
        const tollCost = parseFloat(tollCostStr);
        if (!isNaN(tollCost)) totalTollCost += tollCost;
      }
    });
    setTollCost(totalTollCost);
  };

  const estimateFerryCost = (routes: Route[], ferryRoutes: FerryRoute[], setFerryCost: (ferryCost: number) => void) => {
    const mainRoute = routes.at(0)!;
    const routeNodes = mainRoute.legs[0].annotation.nodes;
    const ferryNodes = ferryRoutes.map(ferryRoute => {
      return { ferryNodeId: ferryRoute.nodes[0], tags: ferryRoute.tags };
    });
    const foundNodes = ferryNodes.filter(ferryNode => routeNodes.find(nodeId => ferryNode.ferryNodeId === nodeId));
    let totalFerryCost = 0;
    foundNodes.forEach(ferryNode => {
      if (ferryNode.tags['charge:motorcar']) {
        const ferryCostStr = ferryNode.tags['charge:motorcar'].split(' ')[1];
        const ferryCost = parseFloat(ferryCostStr);
        if (!isNaN(ferryCost)) totalFerryCost += ferryCost;
      }
    });
    setFerryCost(totalFerryCost);
  };
  useEffect(() => {
    if (routes && ferryRoutes && setFerryCost) {
      estimateFerryCost(routes, ferryRoutes, setFerryCost);
    }
  }, [routes, ferryRoutes]);

  useEffect(() => {
    if (routes && tolls && setTollCost) {
      estimateTollCost(routes, tolls, setTollCost);
    }
  }, [routes, tolls]);
};


